const createPolygonFromPoints = (points) => {
  let { id } = points[0]
  let coords = []
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < points.length; i++) {
    // eslint-disable-next-line no-loop-func
    const currentPoint = points.find((point) => point.id === id)
    id = currentPoint.edge1.id
    coords = [...coords, currentPoint.coordinates]
  }
  return coords
}

export default createPolygonFromPoints
