import styled, { css } from "styled-components"

const StyledContainer = styled.div`
  height: 5.6rem;
  display: flex;
  background: ${({ theme }) => theme.color.white};
  position: relative;
  padding: 1rem 1.2rem 1.2rem 1.6rem;
  width: 100%;

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${({ theme }) => theme.color.secondary05};
    `}
`
const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.2rem;
  width: 100%;
`
const StyledSecondLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const StyledIcon = styled.img`
  height: 3.2rem;
  width: 2.4rem;
  align-self: center;
`
const StyledName = styled.span`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.67;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.color.secondary01};
  white-space: nowrap;
`

const StyledTime = styled.span`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.xs};
  line-height: 1.67;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.color.quinary03};
  margin-left: 0.4rem;
  white-space: nowrap;
`

const StyledTraffic = styled.span`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.xs};
  line-height: 1.67;
  letter-spacing: 0.17px;
  color: ${({ theme }) => theme.color.secondary02};
`

export {
  StyledContainer,
  StyledContentContainer,
  StyledIcon,
  StyledName,
  StyledTime,
  StyledTraffic,
  StyledSecondLine,
}
