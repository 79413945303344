import { useIntl } from "gatsby-plugin-intl"

const locationStatisticTypeMap = {
  ONE_HOUR_DRIVE: "oneHourDrive",
  SUBMARKET: "submarket",
  MARKET: "market",
}

const getLocationStatisticName = (type) => {
  const { formatMessage } = useIntl()

  return formatMessage({
    id: `pages.portfolio.project.location.statistics.tabs.${locationStatisticTypeMap[type]}`,
  })
}

export { getLocationStatisticName as default, locationStatisticTypeMap }
