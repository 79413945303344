import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { isEmptyRedirect } from "utils/redirect"
import { useIntl } from "gatsby-plugin-intl"
import browserWindow from "utils/browserWindow"
import NotFoundSection from "components/content/NotFoundSection"
import SEO from "components/molecules/SEO"
import Layout from "components/molecules/Layout"
import PortfolioNavigation from "components/molecules/PortfolioNavigation"
import ProjectLocation from "components/content/Portfolio/ProjectLocation"
import ClientOnly from "components/atoms/ClientOnly"
import ppLocationProptypes from "utils/ppLocationProptypes"

const PortfolioLocationPage = ({
  data: {
    api: { project },
  },
  pageContext: { language },
  location,
}) => {
  const { formatMessage } = useIntl()
  const [pageOnlineStatus, setPageOnlineStatus] = useState(false)
  const [isBannerActive, setIsBannerActive] = useState(false)
  isEmptyRedirect((project || {}).location)

  useEffect(() => {
    setPageOnlineStatus(browserWindow.navigator.onLine)
  }, [])

  return (
    <Layout
      hideFooter
      isNavStatic
      language={language}
      setIsBannerActive={setIsBannerActive}
    >
      <SEO title="Project location" />
      {(project || {}).location && (
        <ClientOnly>
          <PortfolioNavigation projectData={project} location={location} />
          {pageOnlineStatus ? (
            <ProjectLocation
              sectionData={project}
              isBannerActive={isBannerActive}
            />
          ) : (
            <NotFoundSection
              title={formatMessage({ id: "pages.offline.heading" })}
              contentText={formatMessage({
                id: "pages.offline.subHeading",
              })}
              isSection
            />
          )}
        </ClientOnly>
      )}
    </Layout>
  )
}

PortfolioLocationPage.propTypes = {
  data: PropTypes.shape({
    api: PropTypes.shape({
      project: ppLocationProptypes,
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      selectedPropertyType: PropTypes.string,
      selectedProjectType: PropTypes.string,
    }),
  }).isRequired,
}

export const query = graphql`
  query PortfolioLocationPageData(
    $apiLocale: API_Locale
    $projectSlug: String!
  ) {
    api {
      project(slug: $projectSlug, language: $apiLocale) {
        id
        name
        slug
        location {
          leftTabColumnAddressLine1
          leftTabColumnAddressLine2
          leftTabColumnAddressLine3
          statistics {
            coords
            surveyRegionName
            population
            populationDensity
            medianAge
            medianNetWorth
            medianHouseholdIncome
            totalNumberOfBusiness
            totalNumberOfEmployees
            whiteCollarJobs
            serviceJobs
            blueCollarJobs
            section
          }
          traffic {
            noTrafficAnywhereProject10Min
            noTrafficAnywhereProject30Min
            noTrafficAnywhereProject60Min
            noTrafficProjectAnywhere10Min
            noTrafficProjectAnywhere30Min
            noTrafficProjectAnywhere60Min
            rushHourAnywhereProject10Min
            rushHourAnywhereProject30Min
            rushHourAnywhereProject60Min
            rushHourProjectAnywhere10Min
            rushHourProjectAnywhere30Min
            rushHourProjectAnywhere60Min
          }
          highlightCategories {
            description
            coords
            icon
            image
            name
            whyDoesItMatter
            highlights {
              coords
              description
              image
              name
              type
              whyDoesItMatter
              subhighlights {
                coords
                description
                id
                image
                title
                edge1 {
                  id
                }
              }
            }
          }
          accessibility {
            accessibilityType
            icon
            displayFrame
            values {
              aproxTime
              coords
              name
              trafficThroughput
              icon
            }
          }
        }
        projectTag

        overview {
          city {
            image
            description
            name
          }
        }

        projectPosition {
          point
          area
        }
        ...projectTabs
      }
    }
  }
`

export default PortfolioLocationPage
