/* stylelint-disable no-descending-specificity */
import styled, { css } from "styled-components"
import { transparentize } from "polished"
import CategoryInfoBox from "components/molecules/Map/CategoryInfoBox"

import Icon from "components/atoms/Icon"
import Image from "components/atoms/Image"

const StyledSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: ${({ theme }) => theme.gridWidth};
  width: 100%;

  ${({ theme }) => theme.mq.medium} {
    height: 57.6rem;
    margin: 1.6rem auto;
    padding: 0 ${({ theme }) => theme.layout.mobilePadding};
  }
`

const StyledTabsWrapper = styled.nav`
  width: 100%;

  ${({ theme }) => theme.mq.medium} {
    flex: 0 1 26.8rem;
    margin-right: 1.6rem;
  }

  ${({ theme }) => theme.mq.large} {
    min-width: 26.8rem;
  }
`

const StyledContentWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -100;

  ${({ showMap }) =>
    showMap &&
    css`
      z-index: 100;
    `}

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 8.8rem;
    position: fixed;
    bottom: 0;
    background: linear-gradient(
      0deg,
      ${({ theme }) => transparentize(0.3, theme.color.white)} 0%,
      ${({ theme }) => transparentize(0.92, theme.color.white)} 90%,
      ${({ theme }) => transparentize(1, theme.color.white)} 100%
    );
    pointer-events: none;
    z-index: 101;
  }

  ${({ theme }) => theme.mq.medium} {
    position: relative;
    overflow: auto;
    flex: 0 1 96.3rem;
    display: flex;
    z-index: 1;

    &:after {
      display: none;
    }
  }

  ${({ theme }) => theme.mq.large} {
    flex-direction: row;
    margin-left: 1.6rem;
    overflow: initial;
    min-width: 100rem;
  }
`
const StyledStageNavigation = styled.div`
  height: 4.8rem;
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => theme.color.white};
`

const StyledBackButton = styled.button`
  position: absolute;
  left: 1.6rem;
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  background: transparent;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & svg {
    height: 1.4rem;
    fill: ${({ theme }) => theme.color.secondary02};
  }

  &:hover,
  &:active {
    & svg {
      fill: ${({ theme }) => theme.color.secondary01};
    }
  }
`

const StyledTitle = styled.span`
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
  color: ${({ theme }) => theme.color.secondary01};
`
const StyledIcon = styled(Icon)`
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1.6rem;

  > * {
    fill: inherit;
  }
`

const StyledBlockWrapper = styled.div`
  padding: 1.6rem;
  max-height: 100%;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: linear-gradient(
    to bottom,
    ${({ theme }) => transparentize(1, theme.color.white)},
    ${({ theme }) => transparentize(0.5, theme.color.white)}
  );

  ${({ theme }) => theme.mq.medium} {
    background: ${({ theme }) => theme.color.white};
    background-image: none;
    box-shadow: 0px 0.2rem 0.4rem 0px
      ${({ theme }) => transparentize(0.88, theme.color.main01)};
  }
`
const StyledOverviewImage = styled(Image)`
  width: 100%;
  height: 19.2rem;
  flex: 0 0 19.2rem;
  object-fit: cover;
`
const overviewTitleStyles = css`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.75;
  color: ${({ theme }) => theme.color.secondary01};
  margin: 1rem 0;
  padding: 0;
`
const overviewTextStyles = css`
  overflow-y: auto;
  flex: 1;
  line-height: 1.71;
  color: ${({ theme }) => theme.color.secondary01};
  padding: 0 0 1.6rem;
  hyphens: auto;
  text-align: justify;

  ${({ theme }) => theme.mq.medium} {
    margin-right: -0.6rem;
    padding: 0 1rem 0 0;

    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.color.secondary06};
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: ${({ theme }) => theme.color.secondary06};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.color.secondary03};
    }
  }
`
// Overwriting cupertino-pane styles
const StyledPane = styled.div`
  ${({ overwriteStyles }) =>
    overwriteStyles &&
    css`
      display: block !important;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto !important;
      background: ${({ theme }) => theme.color.white};
    `}
  overflow: visible !important;

  > .pane {
    border-radius: 0 !important;
    overflow: visible !important;
  }

  .draggable {
    height: 4rem !important;
    padding-top: 1.2rem !important;
  }

  .move {
    width: 4.8rem !important;
    height: 4px !important;
    border-radius: 2px !important;
    background-color: ${({ theme }) => theme.color.secondary03} !important;
  }

  ${({ theme }) => theme.mq.medium} {
    display: block !important;
    position: absolute;
    left: 1.6rem;
    top: 1.6rem;
    width: 36rem;
    height: calc(100% - 3.2rem) !important;
    z-index: 1;
    pointer-events: none;
  }
`

const StyledInformation = styled.span`
  display: flex;
  position: absolute;
  z-index: 10;
  box-shadow: 0px 0.2rem 0.4rem 0px
    ${({ theme }) => transparentize(0.88, theme.color.main01)};
  background-color: ${({ theme }) => theme.color.white};
  line-height: 1.75;
  color: ${({ theme }) => theme.color.main03};
  justify-content: center;
  align-items: center;
  padding: 0.6rem 1.6rem;
  bottom: 11rem;
  left: 50%;
  transform: translateX(-50%);
  min-width: calc(100% - 2.4rem);

  ${({ isWithoutPane }) =>
    isWithoutPane &&
    css`
      min-width: 70%;
      bottom: 19rem;
    `}

  > svg {
    min-width: 2.4rem;
    margin-right: 0.8rem;
  }

  ${({ theme }) => theme.mq.medium} {
    bottom: 1.6rem;
    left: calc(50% + 37.6rem);
    min-width: initial;
    width: fit-content;
    max-width: 52.6rem;
    margin: 0;
    min-height: 3.7rem;
    transform: translateX(calc(-50% - 18.8rem));
  }
`

const StyledCategoryInfoBoxWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mq.medium} {
    display: block;
    position: absolute;
    bottom: 1.6rem;
    right: 3rem;
    z-index: 101;
  }
`

const StyledCategoryInfoBox = styled(CategoryInfoBox)`
  width: 55.2rem;
`
const StyledTitleBox = styled.span`
  min-width: 5.6rem;
  height: 3.2rem;
  box-shadow: 0 2px 4px 0
    ${({ theme }) => transparentize(0.88, theme.color.main01)};
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2.4rem 0;
  white-space: nowrap;
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.67;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.color.secondary01};
  position: relative;

  ~ button {
    top: 50%;
    transform: translateY(-50%);
    right: 0.4rem;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0.4rem;
    background-color: ${({ theme }) => theme.color.main03};
  }
`
const customStageListStyles = css`
  > div {
    display: flex;
    align-items: center;
  }
`
export {
  StyledSection,
  StyledTabsWrapper,
  StyledContentWrapper,
  StyledStageNavigation,
  StyledBackButton,
  StyledTitle,
  StyledIcon,
  StyledBlockWrapper,
  StyledOverviewImage,
  overviewTitleStyles,
  overviewTextStyles,
  StyledPane,
  StyledInformation,
  StyledCategoryInfoBoxWrapper,
  StyledCategoryInfoBox,
  StyledTitleBox,
  customStageListStyles,
}
