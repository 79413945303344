import styled, { css } from "styled-components"
import Icon from "components/atoms/Icon"

const StyledTimeWrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mq.medium} {
    > div {
      display: flex;
    }
  }
`

const StyledTime = styled.div`
  display: flex;
  align-items: center;

  :before {
    content: "";
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.8rem;
    margin-left: 1.6rem;

    ${({ timeColor }) =>
      timeColor &&
      css`
        background-color: ${timeColor};
      `}
  }
`
const StyledIcon = styled(Icon)`
  margin-right: 1.6rem;
`
export { StyledTime, StyledTimeWrapper, StyledIcon }
