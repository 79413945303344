import styled, { css } from "styled-components"
import Image from "components/atoms/Image"
import { transparentize } from "polished"

const textStyles = css`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.s};
  line-height: 1.71;
  color: ${({ theme }) => theme.color.secondary01};
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledSelectionBox = styled.span`
  display: none;

  ${({ theme }) => theme.mq.medium} {
    position: relative;
    margin-right: 0.8rem;
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background-color: ${({ theme }) => theme.color.quinary03};

    border-radius: 50%;

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 0.6rem;
      height: 0.6rem;
      background: ${({ theme }) => theme.color.white};
      transform: translateY(-50%) translateX(-50%) rotate(45deg);
    }
  }
`
const StyledBoxWrapper = styled.div`
  cursor: pointer;
  height: 15.2rem;
  width: 100%;
  box-shadow: 0 0.2rem 0.4rem 0
    ${({ theme }) => transparentize(0.88, theme.color.main01)};
  background-color: #ffffff;
  padding: 1.6rem;
  display: flex;
  min-width: 100%;

  &:before {
    content: "";
    position: absolute;
    height: 4px;
    width: 100%;
    left: 0;
    bottom: 0;
    background: transparent;
    background: ${({ theme }) => theme.color.main03};
  }

  ${({ theme }) => theme.mq.medium} {
    width: 43.5rem;
    min-width: initial;
  }
`
const StyledImage = styled(Image)`
  margin-right: 0.8rem;
  width: 12rem;
  height: 12rem;
  min-width: 12rem;
`
const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledHeadingWrapper = styled.div`
  display: flex;
  min-height: 2.4rem;
`
const StyledIcon = styled(Image)`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.8rem;

  /* make icon black first to apply other filters more accordingly */
  filter: brightness(0) saturate(100%);
  > div {
    filter: invert(9%) sepia(8%) saturate(6806%) hue-rotate(184deg)
      brightness(94%) contrast(102%);
  }
`
const StyledCategoryName = styled.p`
  height: 2.4rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  ${textStyles}
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  /* stylelint-enable */
`

const StyledText = styled.p`
  margin-top: 1rem;
  max-height: 7rem;
  hyphens: auto;
  text-align: justify;
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* stylelint-enable */

  ${textStyles}
  ${({ learnMore }) =>
    learnMore === false &&
    css`
      display: block;
      overflow: auto;
      max-height: initial;
      ${({ theme }) => theme.mq.medium} {
        margin-right: -0.6rem;
        padding: 0 1rem 0 0;

        &::-webkit-scrollbar-track {
          background-color: ${({ theme }) => theme.color.secondary06};
        }

        &::-webkit-scrollbar {
          width: 6px;
          background-color: ${({ theme }) => theme.color.secondary06};
        }

        &::-webkit-scrollbar-thumb {
          background-color: ${({ theme }) => theme.color.secondary03};
        }
      }
    `}
`
const StyledDetailsLink = styled.span`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.71;
  color: ${({ theme }) => theme.color.main03};
  position: absolute;
  right: 1.5rem;
  bottom: 0.9rem;

  &:hover {
    color: ${({ theme }) => theme.color.main02};
  }
`
export {
  StyledBoxWrapper,
  StyledImage,
  StyledTextWrapper,
  StyledHeadingWrapper,
  StyledIcon,
  StyledCategoryName,
  StyledText,
  StyledDetailsLink,
  StyledSelectionBox,
}
