import { useIntl } from "gatsby-plugin-intl"
import formatUnit from "utils/formatUnit"

const getLocationStatisticsUnit = (key, unit) => {
  const { formatMessage } = useIntl()

  const units = {
    population: (value) =>
      `${formatUnit(value)} ${formatMessage({
        id: "pages.portfolio.project.location.statistics.labels.population",
      })}`,
    populationDensity: (value) =>
      `${formatUnit(value)} ${formatMessage({
        id:
          "pages.portfolio.project.location.statistics.labels.populationDensity",
      })}`,
    medianAge: (value) =>
      `${value} ${formatMessage({
        id: "pages.portfolio.project.location.statistics.labels.medianAge",
      })}`,
    totalNumberOfBusiness: (value) =>
      `${formatUnit(value)} ${formatMessage({
        id:
          "pages.portfolio.project.location.statistics.labels.totalNumberOfBusiness",
      })}`,
    totalNumberOfEmployees: (value) =>
      `${formatUnit(value)} ${formatMessage({
        id: "pages.portfolio.project.location.statistics.labels.employees",
      })}`,
    whiteCollarJobs: (value) =>
      `${value}${formatMessage({
        id:
          "pages.portfolio.project.location.statistics.labels.employeesPercentage",
      })}`,
    serviceJobs: (value) =>
      `${value}${formatMessage({
        id:
          "pages.portfolio.project.location.statistics.labels.employeesPercentage",
      })}`,
    blueCollarJobs: (value) =>
      `${value}${formatMessage({
        id:
          "pages.portfolio.project.location.statistics.labels.employeesPercentage",
      })}`,
    medianHouseholdIncome: (value) => `$${formatUnit(value)}`,
    medianNetWorth: (value) => `$${formatUnit(value)}`,
    surveyRegionName: (value) => value,
  }

  return units[key](unit)
}

export default getLocationStatisticsUnit
