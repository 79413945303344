import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"
import { CupertinoPane } from "cupertino-pane"
import H from "components/atoms/H"
import P from "components/atoms/P"
import Icon from "components/atoms/Icon"
import getRoute from "utils/getRoute"
import getMediaUrl from "utils/getMediaUrl"
import createPolygonFromPoints from "utils/createPolygonFromPoints"
import useWindowSize from "utils/useWindowSize"
import prepareHiglightsCoords from "utils/prepareHiglightsCoords"
import getTrafficPolygons from "utils/getTrafficPolygons"
import prepareTrafficRoute from "utils/prepareTrafficRoute"
import getTrafficLabel from "utils/getTrafficLabel"
import getAccessibilityTooltipText from "utils/getAccessibilityTooltipText"
import ppLocationProptypes from "utils/ppLocationProptypes"
import createAccessibilityGeometries from "utils/createAccessibilityGeometries"
import createAccessibilityValuesGeometries from "utils/createAccessibilityValuesGeometries"
import activePolygonSubject from "utils/activePolygonSubject"
import activePolylineSubject from "utils/activePolylineSubject"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import theme from "styles/theme"
import ChevronLeft from "assets/icons/chevron-left.svg"
import getTimezone from "utils/getTimezone"
import findHiglight from "utils/findHiglight"
import SideListItem from "components/atoms/SideListItem"
import Map from "components/molecules/Map"
import ProjectLocationStatisticTabs from "./ProjectLocationStatisticTabs"
import ProjectLocationStatisticTooltip from "./ProjectLocationStatisticTabs/ProjectLocationStatisticTooltip"
import ProjectLocationTrafficForm from "./ProjectLocationTraffic/ProjectLocationTrafficForm"
import ProjectLocationTrafficTooltip from "./ProjectLocationTraffic/ProjectLocationTrafficTooltip"
import ProjectLocationHighlights from "./ProjectLocationHighlights"
import ProjectLocationAccessibility from "./ProjectLocationAccessibility"
import {
  StyledSection,
  StyledTabsWrapper,
  StyledContentWrapper,
  StyledStageNavigation,
  StyledBackButton,
  StyledTitle,
  StyledIcon,
  StyledBlockWrapper,
  StyledOverviewImage,
  overviewTitleStyles,
  overviewTextStyles,
  StyledPane,
  StyledInformation,
  StyledCategoryInfoBoxWrapper,
  StyledCategoryInfoBox,
  StyledTitleBox,
  customStageListStyles,
} from "./ProjectLocation.styles"

const ProjectLocation = ({
  sectionData: {
    location,
    name,
    overview: { city },
    projectPosition,
  },
  isBannerActive,
}) => {
  const { statistics, traffic, highlightCategories, accessibility } = location

  const locationTabs = [
    { name: "overview", isVisible: true },
    { name: "accessibility", isVisible: accessibility && accessibility.length },
    {
      name: "highlights",
      isVisible: highlightCategories && highlightCategories.length,
    },
    { name: "statistics", isVisible: statistics && statistics.length },
    { name: "traffic", isVisible: traffic },
  ]

  const setStatisticsDefaultValue = () => {
    if (statistics.length) {
      return statistics.findIndex(
        (statistic) => statistic.section === "SUBMARKET"
      ) >= 0
        ? "SUBMARKET"
        : statistics[0].section
    }
    return null
  }

  const cupertinoRef = useRef(null)
  const mapContainerRef = useRef(null)
  const size = useWindowSize()
  const isDesktop = size.width >= theme.breakpoints.medium
  const [pane, setPane] = useState(null)
  const [isMapVisible, setIsMapVisible] = useState(false)
  const [selectedStatisticSubTab, setSelectedStatisticSubTab] = useState(
    setStatisticsDefaultValue()
  )
  const [trafficValue, setTrafficValue] = useState([])
  const [timezoneOffset, setTimezoneOffset] = useState(0)
  const [formValue, setFormValue] = useState(null)
  const [higlightedCategory, setHiglightedCategory] = useState(null)
  const [higlightValue, setHighlightValue] = useState([])
  const [selectedHighlight, setSelectedHiglight] = useState(null)
  const [subHighlightsDetails, setsubHighlightsDetails] = useState(null)
  const [selectedAccessibility, setSelectedAccessibility] = useState(null)
  const [selectedAccessibilityValue, setSelectedAccessibilityValue] = useState(
    null
  )
  const [accessibilityData, setAccessibilityData] = useState([])
  const [panePosition, setPanePosition] = useState("")
  const [activePolygonRing, setActivePolygonRing] = useState(null)
  const [activePolyline, setActivePolyline] = useState(null)

  const { formatMessage } = useIntl()

  const [activeTab, setActiveTab] = useState(locationTabs[0].name)
  const [activeCategory, setActiveCategory] = useState(
    // eslint-disable-next-line no-nested-ternary
    size.width >= theme.breakpoints.medium
      ? null
      : highlightCategories[0]
      ? highlightCategories[0].name
      : []
  )

  const handleCloseMap = () => setIsMapVisible(false)

  const getTimezoneOffset = async () => {
    const { rawOffset, dstOffset } = await getTimezone(
      projectPosition.point.coordinates
    )
    setTimezoneOffset(rawOffset + dstOffset)
  }

  useEffect(() => {
    getTimezoneOffset()
  }, [])

  useEffect(() => {
    if (activeTab === "traffic" || activeTab === "statistics") {
      activePolygonSubject.attach(setActivePolygonRing)
    } else {
      activePolygonSubject.detach(setActivePolygonRing)
    }

    return activePolygonSubject.detach(setActivePolygonRing)
  }, [activeTab])

  useEffect(() => {
    if (activeTab === "accessibility") {
      activePolylineSubject.attach(setActivePolyline)
    } else {
      activePolylineSubject.detach(setActivePolyline)
    }

    return activePolylineSubject.detach(setActivePolyline)
  }, [activeTab])

  const handlePaneDrag = (cupertinoPane) =>
    setPanePosition(cupertinoPane.currentBreak())

  useEffect(() => {
    if (cupertinoRef) {
      const cupertinoPane = new CupertinoPane(cupertinoRef.current, {
        bottomOffset: 0,
        buttonClose: false,
        dragBy: [".pane .draggable"],
        initialBreak: "bottom",
        onTransitionEnd: () => handlePaneDrag(cupertinoPane),
      })
      setPane(cupertinoPane)
    }
  }, [cupertinoRef])

  useEffect(() => {
    if (pane) {
      if (
        !isDesktop &&
        ["overview", "statistics", "highlights"].includes(activeTab)
      ) {
        pane.present({ animate: true })
      } else {
        pane.destroy()
      }
    }
    if (activeTab === "accessibility" && !selectedAccessibility) {
      setSelectedAccessibility("KEY_LOCAL_ROADS")
    }
  }, [pane, activeTab])

  const handleCategoryClick = (selectionName, higlightType) => {
    setHiglightedCategory(null)
    if (higlightType) {
      setSelectedHiglight(selectionName)
    } else {
      setActiveCategory(selectionName)
    }
  }

  const setMultiAdressHiglight = (points, destination, subHighlightData) => {
    const coordinates = [createPolygonFromPoints(points)]

    const origin = projectPosition.point.coordinates
    if (destination) {
      setsubHighlightsDetails(subHighlightData)
      getRoute(origin, destination, new Date(), (response) => {
        const labelValue = {
          fromValue: { label: name, value: "Project" },
          toValue: { label: "destination" },
        }

        setHighlightValue([
          {
            type: "Polygon",
            coordinates,
            subType: "",
            name: "Polygon",
          },
          ...points,
          ...prepareTrafficRoute(response, labelValue),
        ])
      })
    } else {
      setHighlightValue([
        {
          type: "Polygon",
          coordinates,
          subType: "",
          name: "Polygon",
        },
        ...points,
      ])
    }
  }

  const getHiglight = () => {
    const higlight = findHiglight(
      highlightCategories,
      activeCategory,
      selectedHighlight
    )

    if (higlight.type === "SINGLE_ADDRESS") {
      const origin = projectPosition.point.coordinates
      const destination = higlight.coords.coordinates
      getRoute(origin, destination, new Date(), (response) => {
        const labelValue = {
          fromValue: { label: name, value: "Project" },
          toValue: { label: higlight.name },
        }
        setHighlightValue(prepareTrafficRoute(response, labelValue))
      })
    } else {
      const points = higlight.subhighlights.map((subhighlight) => {
        const { coords, id, edge1, ...subHighlightData } = subhighlight
        return {
          ...coords,
          markerIcon: getMediaUrl(higlight.icon.medium),
          subType: "customHighlight",
          id,
          edge1,
          name: higlight.name + subhighlight.title,
          onInfoBoxClick: (marker) =>
            setMultiAdressHiglight(points, marker, subHighlightData),
          infoBoxContent: <StyledTitleBox>{subhighlight.title}</StyledTitleBox>,
          clickHandler: (_, marker) =>
            setMultiAdressHiglight(points, marker, subHighlightData),
        }
      })
      setMultiAdressHiglight(points, null, higlight.subhighlights[0])
    }
  }

  const onCategoryHover = (value, hoveredCategoryName) => {
    if (value) {
      setHiglightedCategory(hoveredCategoryName)
    } else {
      setHiglightedCategory(null)
    }
  }

  useEffect(() => {
    if (selectedHighlight && activeCategory) {
      getHiglight()
    } else {
      setsubHighlightsDetails(null)
      setHighlightValue(
        prepareHiglightsCoords(
          highlightCategories,
          higlightedCategory,
          activeCategory,
          handleCategoryClick,
          onCategoryHover
        )
      )
    }
  }, [activeCategory, higlightedCategory, selectedHighlight])

  useEffect(() => {
    if (selectedAccessibility) {
      const activeAccessibilityData = accessibility.find(
        (currentAccessibility) =>
          currentAccessibility.accessibilityType === selectedAccessibility
      )

      if (isDesktop) {
        const setRouteToPoint = (pinLocation) => {
          const origin = projectPosition.point.coordinates
          const destination = pinLocation
          getRoute(origin, destination, new Date(), (response) => {
            const labelValue = {
              fromValue: { label: name, value: "Project" },
              toValue: { label: "123" },
            }
            setAccessibilityData([
              ...prepareTrafficRoute(response, labelValue, false),
              activeAccessibilityData.displayFrame,
              // eslint-disable-next-line no-use-before-define
              ...geometries,
            ])
          })
        }

        const geometries = createAccessibilityGeometries(
          activeAccessibilityData,
          selectedAccessibility,
          setRouteToPoint
        )
        if (selectedAccessibility !== "KEY_LOCAL_ROADS") {
          const firstPointCoordinates = activeAccessibilityData.values[0].coords.geometries.find(
            (geometry) => geometry.type === "Point"
          ).coordinates
          setRouteToPoint(firstPointCoordinates)
        } else {
          setAccessibilityData([
            activeAccessibilityData.displayFrame,
            ...geometries,
          ])
        }
      } else {
        setSelectedAccessibilityValue(activeAccessibilityData.values[0].name)
      }
    }
  }, [selectedAccessibility])

  useEffect(() => {
    if (selectedAccessibilityValue) {
      let valueIndex = 0
      const activeAccessibilityValueData = accessibility
        .find(
          (currentAccessibility) =>
            currentAccessibility.accessibilityType === selectedAccessibility
        )
        .values.find((value, index) => {
          valueIndex = index
          return value.name === selectedAccessibilityValue
        })

      const setRouteToPoint = (pinLocation) => {
        const origin = projectPosition.point.coordinates
        const destination = pinLocation
        getRoute(origin, destination, new Date(), (response) => {
          const labelValue = {
            fromValue: { label: name, value: "Project" },
            toValue: { label: "123" },
          }
          setAccessibilityData([
            ...prepareTrafficRoute(response, labelValue, false),
            // eslint-disable-next-line no-use-before-define
            ...geometries,
          ])
        })
      }

      const geometries = createAccessibilityValuesGeometries(
        activeAccessibilityValueData,
        selectedAccessibility,
        valueIndex
      )

      if (selectedAccessibility !== "KEY_LOCAL_ROADS") {
        const firstPointCoordinates = activeAccessibilityValueData.coords.geometries.find(
          (geometry) => geometry.type === "Point"
        ).coordinates
        setRouteToPoint(firstPointCoordinates)
      } else {
        setAccessibilityData(geometries)
      }
    }
  }, [selectedAccessibilityValue])

  const stageMobileNavigation = () => (
    <StyledStageNavigation>
      <StyledBackButton type="button" onClick={handleCloseMap}>
        <ChevronLeft />
        {formatMessage({
          id: "back",
        })}
      </StyledBackButton>
      <StyledTitle>
        {formatMessage({
          id: `pages.portfolio.project.location.tabs.${activeTab}`,
        })}
      </StyledTitle>
    </StyledStageNavigation>
  )

  const handleResetState = () => {
    setHiglightedCategory(null)
    setSelectedHiglight(null)
    setSelectedAccessibility(null)
    setSelectedAccessibilityValue(null)
    setAccessibilityData([])
    setSelectedStatisticSubTab(setStatisticsDefaultValue())
    // eslint-disable-next-line no-unused-expressions
    isDesktop && setActiveCategory(null)
  }

  const handleActiveTab = (selectedTab) => {
    setActiveTab(selectedTab)
    setIsMapVisible(true)

    handleResetState()
  }

  const getRouteRequestArgument = (formValues) =>
    formValues.value === "Project"
      ? projectPosition.point.coordinates
      : formValues.value

  const handleFormChange = (formValues) => {
    setFormValue(formValues)
  }

  useEffect(() => {
    if (formValue && traffic) {
      const { whenValue, fromValue, toValue } = formValue || {}
      const polygonValue = whenValue.value + fromValue.value + toValue.value

      if (traffic[`${polygonValue}10Min`]) {
        setTrafficValue([
          ...getTrafficPolygons(polygonValue, traffic),
          ...getTrafficLabel(traffic, polygonValue).map((label) => ({
            ...label,
            text: `${label.text} ${formatMessage({
              id: `pages.portfolio.project.location.traffic.minutesDrive`,
            })}`,
          })),
          {
            ...projectPosition.point,
            subType: "active",
            infoBoxContent: !isDesktop && (
              <StyledTitleBox>{name}</StyledTitleBox>
            ),
          },
        ])
      } else {
        const trafficTime = {
          now: new Date(),
          noTraffic: new Date((4758408000 - timezoneOffset) * 1000), // 15/10/2120 4:00 am - Project timezone
          rushHour: new Date((4758624900 - timezoneOffset) * 1000), // 17/10/2120 4:15 PM - Project timezone
        }
        const origin = getRouteRequestArgument(fromValue)
        const destination = getRouteRequestArgument(toValue)

        getRoute(
          origin,
          destination,
          trafficTime[whenValue.value],
          (response) => {
            setTrafficValue([
              ...prepareTrafficRoute(response, formValue),
              { ...projectPosition.point, subType: "active" },
            ])
          }
        )
      }
    }
  }, [formValue])

  const handleCategoryHover = (categoryName) => {
    setHiglightedCategory(categoryName)
  }

  const renderTabs = (tab) => (
    <SideListItem
      isActive={activeTab === tab.name && isDesktop}
      onClick={() => {
        handleActiveTab(tab.name)
        trackCustomEventTrigger({
          category: trackingCategoriesHelper.PROJECT_LOCATION,
          action: "Click",
          label: `${trackingCategoriesHelper.PROJECT_LOCATION} link(${tab.name})`,
        })
      }}
      key={tab.name}
      extraStyles={customStageListStyles}
    >
      <StyledIcon icon={tab.name} />
      {formatMessage({
        id: `pages.portfolio.project.location.tabs.${tab.name}`,
      })}
    </SideListItem>
  )

  const mapPolygons = (polygons) =>
    polygons.map((polygon) => ({
      subType: "overview",
      ...polygon.area,
    }))

  const mapStatistics = (regionData) => {
    if (regionData) {
      return regionData.coords.geometries
        .map((geometry) => {
          if (geometry.type === "Point") {
            return {
              ...geometry,
              type: "PolygonInfoLabel",
              text: regionData.surveyRegionName,
            }
          }
          return geometry
        })
        .map((geometry) => ({ subType: regionData.section, ...geometry }))
    }
    return []
  }

  const content = {
    overview: (
      <>
        <StyledOverviewImage src={getMediaUrl(city.image)} alt={city.name} />
        <H as="h5" extraStyles={overviewTitleStyles}>
          {city.name}
        </H>
        <P extraStyles={overviewTextStyles} className="content" overflow-y>
          {city.description}
        </P>
      </>
    ),
    accessibility: (
      <ProjectLocationAccessibility
        accessibilityData={accessibility}
        selectedAccessibility={selectedAccessibility}
        onAccessibilityChange={setSelectedAccessibility}
        onAccessibilityValueChange={setSelectedAccessibilityValue}
        selectedAccessibilityValue={selectedAccessibilityValue}
      />
    ),
    highlights: (
      <ProjectLocationHighlights
        onHover={handleCategoryHover}
        highlightCategories={highlightCategories}
        onCategoryChange={handleCategoryClick}
        selectedCategory={activeCategory}
        selectedHighlight={selectedHighlight}
        higlightedCategory={higlightedCategory}
        panePosition={panePosition}
      />
    ),
    statistics: (
      <ProjectLocationStatisticTabs
        tabsData={statistics}
        handleTabChange={setSelectedStatisticSubTab}
        selectedTab={selectedStatisticSubTab}
      />
    ),
    traffic: (
      <ProjectLocationTrafficForm
        onChange={handleFormChange}
        projectName={name}
        projectLocation={projectPosition.point}
        timezoneOffset={timezoneOffset}
      />
    ),
  }

  const mapData = {
    overview: [
      ...mapPolygons([projectPosition]),
      {
        ...projectPosition.point,
        subType: "hidden",
      },
    ],
    accessibility: [
      {
        ...projectPosition.point,
        subType: "active",
        infoBoxContent: !isDesktop && <StyledTitleBox>{name}</StyledTitleBox>,
      },
      ...accessibilityData.filter(
        (accessibilityLocation) =>
          !isDesktop ||
          (accessibilityLocation.type !== "KeyRoadInfoBox" &&
            accessibilityLocation.type !== "KeyPointInfoBox" &&
            accessibilityLocation.type !== "HighwayInfoBox") ||
          activePolyline.find(
            (polylineName) =>
              polylineName === "all" ||
              polylineName === accessibilityLocation.name
          )
      ),
    ],
    highlights: [
      {
        ...projectPosition.point,
        subType: "active",
        infoBoxContent: !isDesktop && <StyledTitleBox>{name}</StyledTitleBox>,
      },
      ...higlightValue,
    ],
    statistics: [
      ...mapStatistics(
        statistics.filter(
          (statistic) => statistic.section === selectedStatisticSubTab
        )[0]
      ).filter(
        (statistic) =>
          statistic.type !== "PolygonInfoLabel" ||
          statistic.subType === activePolygonRing
      ),
      {
        ...projectPosition.point,
        subType: "active",
        infoBoxContent: !isDesktop && <StyledTitleBox>{name}</StyledTitleBox>,
      },
    ],
    traffic: trafficValue.filter((trafficVal) => {
      const trimActiveRing = activePolygonRing
        ? activePolygonRing.replace(/[^0-9]/g, "")
        : ""

      return (
        trafficVal.type !== "PolygonInfoLabel" ||
        trafficVal.subType === trimActiveRing
      )
    }),
  }

  const getInfoText = () => {
    if (activeCategory) {
      if (!isDesktop) {
        return {
          id: "pages.portfolio.project.location.highlights.highlightInfoMobile",
        }
      }
      return {
        id: "pages.portfolio.project.location.highlights.highlightInfoDesktop",
      }
    }

    return { id: "pages.portfolio.project.location.highlights.categoryInfo" }
  }

  return (
    <StyledSection>
      <>
        <StyledTabsWrapper>
          {locationTabs &&
            locationTabs
              .filter((tab) => tab.isVisible)
              .map((tab) => renderTabs(tab))}
        </StyledTabsWrapper>
        <StyledContentWrapper showMap={isMapVisible} ref={mapContainerRef}>
          <StyledPane
            ref={cupertinoRef}
            overwriteStyles={
              !isDesktop &&
              !["overview", "statistics", "highlights"].includes(activeTab)
            }
          >
            <StyledBlockWrapper>{content[activeTab]}</StyledBlockWrapper>
          </StyledPane>

          {activeTab && !isDesktop && stageMobileNavigation()}

          {location && (
            <Map
              projectName={name}
              projectAddress={location}
              mapType={activeTab}
              projects={mapData[activeTab]}
              projectPosition={projectPosition.point}
              mapContainerRef={mapContainerRef}
              isBannerActive={isBannerActive}
            />
          )}

          {activeTab === "highlights" &&
            ((subHighlightsDetails && (
              <StyledCategoryInfoBoxWrapper>
                <StyledCategoryInfoBox
                  image={getMediaUrl(subHighlightsDetails.image)}
                  categoryText={subHighlightsDetails.description}
                  categoryName={subHighlightsDetails.title}
                  learnMore={false}
                />
              </StyledCategoryInfoBoxWrapper>
            )) ||
              (isDesktop &&
                higlightValue.find((value) => value.type === "Polygon") && (
                  <StyledInformation>
                    <Icon icon="info" />
                    {formatMessage({
                      id:
                        "pages.portfolio.project.location.highlights.selectSubhighlight",
                    })}
                  </StyledInformation>
                )))}

          {activeTab === "highlights" && selectedHighlight && !isDesktop && (
            <StyledInformation>
              <Icon icon="info" />
              {formatMessage({
                id: "pages.portfolio.project.location.highlights.swipeUp",
              })}
            </StyledInformation>
          )}

          {activeTab === "highlights" && !selectedHighlight && (
            <StyledInformation>
              <Icon icon="info" />
              {formatMessage(getInfoText())}
            </StyledInformation>
          )}

          {activeTab === "traffic" && (
            <StyledInformation isWithoutPane>
              <ProjectLocationTrafficTooltip />
            </StyledInformation>
          )}

          {activeTab === "accessibility" && isDesktop && (
            <StyledInformation isWithoutPane>
              <Icon icon="info" />
              {selectedAccessibility &&
                getAccessibilityTooltipText(selectedAccessibility)}
            </StyledInformation>
          )}
          {activeTab === "statistics" && (
            <StyledInformation>
              <ProjectLocationStatisticTooltip
                details={mapStatistics(
                  statistics.filter(
                    (statistic) => statistic.section === selectedStatisticSubTab
                  )[0]
                )}
              />
            </StyledInformation>
          )}
        </StyledContentWrapper>
      </>
    </StyledSection>
  )
}

ProjectLocation.propTypes = {
  sectionData: ppLocationProptypes.isRequired,
  isBannerActive: PropTypes.bool,
}

ProjectLocation.defaultProps = {
  isBannerActive: false,
}

export default ProjectLocation
