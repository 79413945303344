import React from "react"
import PropTypes from "prop-types"
import PinPurple from "components/molecules/Map/mapAssets/pin-purple.png"
import PinOrange from "components/molecules/Map/mapAssets/pin-orange.png"
import PinTeal from "components/molecules/Map/mapAssets/pin-teal.png"
import PinYellow from "components/molecules/Map/mapAssets/pin-yellow.png"

import {
  StyledContainer,
  StyledContentContainer,
  StyledName,
  StyledTime,
  StyledTraffic,
  StyledSecondLine,
  StyledIcon,
} from "./KeyRoadsBlock.styles"

const pins = {
  orange: PinOrange,
  teal: PinTeal,
  yellow: PinYellow,
  purple: PinPurple,
}

const KeyRoadsBlock = ({
  aproxTime,
  name,
  trafficThroughput,
  colors,
  isActive,
  onClick,
}) => {
  return (
    <StyledContainer isActive={isActive} onClick={() => onClick(name)}>
      <StyledIcon src={pins[colors.name]} />

      <StyledContentContainer>
        <StyledName>{name}</StyledName>

        <StyledSecondLine>
          <StyledTraffic>{trafficThroughput}</StyledTraffic>
          {aproxTime && <StyledTime>({aproxTime})</StyledTime>}
        </StyledSecondLine>
      </StyledContentContainer>
    </StyledContainer>
  )
}

KeyRoadsBlock.propTypes = {
  aproxTime: PropTypes.string,
  name: PropTypes.string,
  trafficThroughput: PropTypes.string,
  colors: PropTypes.shape({
    name: PropTypes.string,
    body: PropTypes.string,
    border: PropTypes.string,
  }).isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}
KeyRoadsBlock.defaultProps = {
  aproxTime: null,
  name: null,
  trafficThroughput: null,
  isActive: false,
  onClick: () => null,
}
export default KeyRoadsBlock
