import theme from "styles/theme"
import HubPurple from "components/molecules/Map/mapAssets/hub-purple.png"
import HubOrange from "components/molecules/Map/mapAssets/hub-orange.png"
import HubTeal from "components/molecules/Map/mapAssets/hub-teal.png"
import HubYellow from "components/molecules/Map/mapAssets/hub-yellow.png"
import HighwayPurple from "components/molecules/Map/mapAssets/highway-purple.png"
import HighwayOrange from "components/molecules/Map/mapAssets/highway-orange.png"
import HighwayTeal from "components/molecules/Map/mapAssets/highway-teal.png"
import HighwayYellow from "components/molecules/Map/mapAssets/highway-yellow.png"
import EndpointPurple from "components/molecules/Map/mapAssets/endpoint-purple.png"
import EndpointOrange from "components/molecules/Map/mapAssets/endpoint-orange.png"
import EndpointTeal from "components/molecules/Map/mapAssets/endpoint-teal.png"
import EndpointYellow from "components/molecules/Map/mapAssets/endpoint-yellow.png"

const { color } = theme

const hubPins = {
  orange: HubOrange,
  teal: HubTeal,
  yellow: HubYellow,
  purple: HubPurple,
}

const highwayPins = {
  orange: HighwayOrange,
  teal: HighwayTeal,
  yellow: HighwayYellow,
  purple: HighwayPurple,
}

const endPins = {
  orange: EndpointOrange,
  teal: EndpointTeal,
  yellow: EndpointYellow,
  purple: EndpointPurple,
}

const colors = {
  0: {
    name: "teal",
    body: color.quaternary03,
    border: color.quaternary02,
  },
  1: { name: "orange", body: color.septenary03, border: color.septenary02 },
  2: { name: "yellow", body: color.senary03, border: color.senary02 },
  3: { name: "purple", body: color.octonary01, border: color.octonary02 },
}

const createInfoBoxOptions = (
  geometry,
  accesibilityStructure,
  selectedAccessibility,
  index,
  handlePinClick
) => {
  const { name } = accesibilityStructure
  if (selectedAccessibility === "TRANSPORTATION_HUBS") {
    return [
      {
        ...geometry,
        markerIcon: hubPins[colors[index].name],
        subType: "custom",
        clickHandler: handlePinClick,
      },
    ]
  }
  if (selectedAccessibility === "KEY_LOCAL_ROADS") {
    return {
      text: name,
      type: "PolygonInfoLabel",
      subType: name,
      coordinates: geometry.coordinates,
      color: colors[index].body,
    }
  }
  return [
    {
      ...geometry,
      markerIcon: highwayPins[colors[index].name],
      subType: "custom",
      clickHandler: handlePinClick,
    },
    {
      color: colors[index].body,
      type: "PolygonInfoLabel",
      coordinates: geometry.coordinates,
      text: name,
      subType: "centred",
    },
  ]
}

const createAccessibilityValuesGeometries = (
  activeAccessibilityValueData,
  selectedAccessibility,
  valueIndex
) => {
  const colorNumber = valueIndex % 4

  return activeAccessibilityValueData.coords.geometries.flatMap((geometry) => {
    const lastCoordinate = geometry.coordinates.length - 1
    return geometry.type === "Point"
      ? createInfoBoxOptions(
          geometry,
          activeAccessibilityValueData,
          selectedAccessibility,
          colorNumber
        )
      : [
          {
            ...geometry,
            strokeColor: colors[colorNumber].border,
            strokeWeight: 4,
          },
          {
            ...geometry,
            subType: activeAccessibilityValueData.name,
            strokeColor: colors[colorNumber].body,
            strokeWeight: 3,
          },
          {
            type: "Point",
            coordinates: geometry.coordinates[0],
            markerIcon: endPins[colors[colorNumber].name],
            subType: "custom",
          },
          {
            type: "Point",
            coordinates: geometry.coordinates[lastCoordinate],
            markerIcon: endPins[colors[colorNumber].name],
            subType: "custom",
          },
        ]
  })
}

export default createAccessibilityValuesGeometries
