import React from "react"
import PropTypes from "prop-types"

import CategoryList from "./CategoryList"
import CategoryDetails from "./CategoryDetails"

const ProjectLocationHighlights = ({
  highlightCategories,
  onHover,
  onCategoryChange,
  selectedCategory,
  selectedHighlight,
  panePosition,
  higlightedCategory,
}) => {
  const getSelectedCategoryData = () =>
    highlightCategories.find((category) => category.name === selectedCategory)

  const getSelectedHiglightData = () =>
    selectedHighlight &&
    getSelectedCategoryData().highlights.find(
      (highlight) => highlight.name === selectedHighlight
    )

  const getCategoryList = () =>
    highlightCategories.map((category) => ({
      value: category.name,
      label: category.name,
    }))

  return (
    <>
      {selectedCategory ? (
        <CategoryDetails
          categoryList={getCategoryList()}
          categoryData={
            selectedHighlight
              ? getSelectedHiglightData()
              : getSelectedCategoryData()
          }
          onCategoryChange={onCategoryChange}
          panePosition={panePosition}
        />
      ) : (
        <CategoryList
          onClick={onCategoryChange}
          onHover={onHover}
          highlightCategories={highlightCategories}
          higlightedCategory={higlightedCategory}
        />
      )}
    </>
  )
}

ProjectLocationHighlights.propTypes = {
  highlightCategories: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      icon: PropTypes.oneOfType([
        PropTypes.shape({
          original: PropTypes.string,
          large: PropTypes.string,
          medium: PropTypes.string,
          small: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      image: PropTypes.oneOfType([
        PropTypes.shape({
          original: PropTypes.string,
          large: PropTypes.string,
          medium: PropTypes.string,
          small: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      name: PropTypes.string,
      whyDoesItMatter: PropTypes.string,
      highlights: PropTypes.arrayOf(
        PropTypes.shape({
          coords: PropTypes.shape({
            type: PropTypes.string,
            coordinates: PropTypes.arrayOf(PropTypes.number),
          }),
          description: PropTypes.string,
          image: PropTypes.oneOfType([
            PropTypes.shape({
              original: PropTypes.string,
              large: PropTypes.string,
              medium: PropTypes.string,
              small: PropTypes.string,
            }),
            PropTypes.string,
          ]),
          name: PropTypes.string,
          type: PropTypes.string,
          whyDoesItMatter: PropTypes.string,
          subhighlights: PropTypes.arrayOf(
            PropTypes.shape({
              coords: PropTypes.shape({
                type: PropTypes.string,
                coordinates: PropTypes.arrayOf(PropTypes.number),
              }),
              description: PropTypes.string,
              id: PropTypes.string,
              image: PropTypes.oneOfType([
                PropTypes.shape({
                  original: PropTypes.string,
                  large: PropTypes.string,
                  medium: PropTypes.string,
                  small: PropTypes.string,
                }),
                PropTypes.string,
              ]),
              title: PropTypes.string,
            })
          ),
        })
      ),
    })
  ).isRequired,
  onHover: PropTypes.func.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string,
  panePosition: PropTypes.string.isRequired,
  selectedHighlight: PropTypes.string,
  higlightedCategory: PropTypes.string,
}

ProjectLocationHighlights.defaultProps = {
  selectedCategory: null,
  selectedHighlight: null,
  higlightedCategory: null,
}
export default ProjectLocationHighlights
