import React from "react"
import PropTypes from "prop-types"

import Icon from "components/atoms/Icon"

import {
  StyledProjectBlock,
  StyledBlockLabel,
  StyledBlockText,
} from "./ProjectBlock.styles"

const ProjectBlock = ({ blockData: { label, value, iconName }, className }) => {
  return (
    <StyledProjectBlock className={className}>
      <Icon icon={iconName} />
      <StyledBlockLabel>{label}</StyledBlockLabel>
      <StyledBlockText>{value}</StyledBlockText>
    </StyledProjectBlock>
  )
}

ProjectBlock.propTypes = {
  blockData: PropTypes.shape({
    label: PropTypes.string,
    iconName: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  className: PropTypes.string,
}

ProjectBlock.defaultProps = {
  className: "",
}

export default ProjectBlock
