import styled, { css } from "styled-components"
import Image from "components/atoms/Image"

const StyledTabTitle = styled.span`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.75;
  color: ${({ theme }) => theme.color.secondary01};
  margin-bottom: 0.4rem;
  padding-bottom: 0.4rem;
  border-bottom: solid 1px ${({ theme }) => theme.color.secondary04};
`

const StyledCategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledCategoryIcon = styled(Image)`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1.2rem;
  transition: filter 0.5s;

  /* make icon black to apply other filters more accordingly */
  filter: brightness(0) saturate(100%);
`

const StyledCategory = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem;
  cursor: pointer;
  margin-left: -0.8rem;
  position: relative;
  color: ${({ theme }) => theme.color.secondary02};
  transition: color 0.5s;

  &:last-child {
    margin-bottom: -0.8rem;
  }

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 0.4rem;
    background: transparent;
    left: -0.8rem;
  }

  > ${StyledCategoryIcon} {
    filter: invert(35%) sepia(58%) saturate(218%) hue-rotate(178deg)
      brightness(95%) contrast(96%);
  }

  &:hover {
    &:before {
      background: ${({ theme }) => theme.color.main03};
    }

    color: ${({ theme }) => theme.color.secondary01};
    > ${StyledCategoryIcon} {
      filter: invert(9%) sepia(8%) saturate(6806%) hue-rotate(184deg)
        brightness(94%) contrast(102%);
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &:before {
        background: ${({ theme }) => theme.color.main03};
      }
      color: ${({ theme }) => theme.color.secondary01};
      > ${StyledCategoryIcon} {
        filter: invert(9%) sepia(8%) saturate(6806%) hue-rotate(184deg)
          brightness(94%) contrast(102%);
      }
    `}
`

const StyledCategoryName = styled.span`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.67;
  letter-spacing: 0.25px;
  color: inherit;
`

export {
  StyledTabTitle,
  StyledCategoriesWrapper,
  StyledCategory,
  StyledCategoryIcon,
  StyledCategoryName,
}
