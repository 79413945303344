const getTimezone = async (point) => {
  const timestmap = Date.now() / 1000
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/timezone/json?location=${point[1]},${point[0]}&timestamp=${timestmap}&key=${process.env.GATSBY_GOOGLE_API_KEY}`
  )
  const body = await response.json()
  return body
}

export default getTimezone
