const pickOnlyPolygon = (geometryList) =>
  geometryList.filter((geometry) => geometry.type !== "Point")

const getTrafficPolygons = (polygonValue, traffic) => [
  {
    subType: `${polygonValue}60Min`,
    innerCoords: [
      ...pickOnlyPolygon(traffic[`${polygonValue}30Min`].geometries)[0]
        .coordinates[0],
    ].reverse(),
    ...pickOnlyPolygon(traffic[`${polygonValue}60Min`].geometries)[0],
  },
  {
    subType: `${polygonValue}30Min`,
    innerCoords: [
      ...pickOnlyPolygon(traffic[`${polygonValue}10Min`].geometries)[0]
        .coordinates[0],
    ].reverse(),
    ...pickOnlyPolygon(traffic[`${polygonValue}30Min`].geometries)[0],
  },
  {
    subType: `${polygonValue}10Min`,
    ...pickOnlyPolygon(traffic[`${polygonValue}10Min`].geometries)[0],
  },
]

export default getTrafficPolygons
