import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"

import ProjectBlock from "components/atoms/ProjectBlock"
import getLocationStatisticName, {
  locationStatisticTypeMap,
} from "utils/getLocationStatisticName"
import getLocationStatisticsUnit from "utils/getLocationStatisticsUnit"

import {
  StyledProjectBlocksWrapper,
  StyledNavList,
} from "./ProjectLocationStatisticTabs.styles"

const typeOrder = ["SUBMARKET", "MARKET", "ONE_HOUR_DRIVE"]

const ProjectLocationStatisticTabs = ({
  tabsData,
  handleTabChange,
  selectedTab,
}) => {
  const navContent = typeOrder
    .map((type) => tabsData.find((location) => location.section === type))
    .filter((location) => location && location.section)
    .map((location) => ({
      icon: locationStatisticTypeMap[location.section],
      label: getLocationStatisticName(location.section),
      role: "button",
      id: location.section,
    }))

  const { formatMessage } = useIntl()

  const getDataKeys = () => {
    const { coords, section, ...rest } = tabsData[0] || {}
    return Object.keys(rest)
  }

  const getDataForSelectedTab = (key) => {
    const value = tabsData.find((location) => location.section === selectedTab)[
      key
    ]

    return getLocationStatisticsUnit(key, value)
  }

  const createTab = () =>
    getDataKeys().map((key) => (
      <ProjectBlock
        key={key}
        blockData={{
          label: formatMessage({
            id: `pages.portfolio.project.location.statistics.${key}`,
          }),
          value: getDataForSelectedTab(key),
          iconName: key,
        }}
      />
    ))

  return (
    <>
      <StyledNavList
        navContent={navContent}
        onChange={handleTabChange}
        selected={selectedTab}
      />
      <StyledProjectBlocksWrapper>{createTab()}</StyledProjectBlocksWrapper>
    </>
  )
}

ProjectLocationStatisticTabs.propTypes = {
  tabsData: PropTypes.arrayOf(
    PropTypes.shape({
      coords: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.arrayOf(
          PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
        ),
      }),
      blueCollarJobs: PropTypes.number,
      medianAge: PropTypes.number,
      medianHouseholdIncome: PropTypes.number,
      medianNetWorth: PropTypes.number,
      population: PropTypes.number,
      populationDensity: PropTypes.number,
      section: PropTypes.string,
      serviceJobs: PropTypes.number,
      surveyRegionName: PropTypes.string,
      totalNumberOfBusiness: PropTypes.number,
      totalNumberOfEmployees: PropTypes.number,
      whiteCollarJobs: PropTypes.number,
    })
  ).isRequired,
  handleTabChange: PropTypes.func.isRequired,
  selectedTab: PropTypes.string,
}
ProjectLocationStatisticTabs.defaultProps = {
  selectedTab: null,
}
export default ProjectLocationStatisticTabs
