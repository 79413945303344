import styled, { css } from "styled-components"
import Icon from "components/atoms/Icon"

const StyledTooltipContent = styled.div`
  display: flex;
  align-items: center;

  :before {
    content: "";
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.8rem;
    margin-left: 1.6rem;

    ${({ color }) =>
      color &&
      css`
        background-color: ${color};
      `}
  }
`

const StyledTooltipWrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mq.medium} {
    > div {
      display: flex;
    }
  }
`

const StyledIcon = styled(Icon)`
  margin-right: 1.6rem;
  flex: 0 0 auto;
`

export { StyledTooltipWrapper, StyledTooltipContent, StyledIcon }
