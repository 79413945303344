import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"
import getMediaUrl from "utils/getMediaUrl"
import HighwayPurple from "components/molecules/Map/mapAssets/highway-purple.png"
import HighwayOrange from "components/molecules/Map/mapAssets/highway-orange.png"
import HighwayTeal from "components/molecules/Map/mapAssets/highway-teal.png"
import HighwayYellow from "components/molecules/Map/mapAssets/highway-yellow.png"

import {
  StyledContainer,
  StyledContentContainer,
  StyledName,
  StyledRampInfo,
  StyledFirstLine,
  StyledIcon,
  StyledPointIcon,
  StyledLine,
} from "./HighwaysBlock.styles"

const pins = {
  orange: HighwayOrange,
  teal: HighwayTeal,
  yellow: HighwayYellow,
  purple: HighwayPurple,
}

const HighwaysBlock = ({ name, colors, icon, isActive, onClick }) => {
  const { formatMessage } = useIntl()

  return (
    <StyledContainer isActive={isActive} onClick={() => onClick(name)}>
      {icon && <StyledIcon src={getMediaUrl(icon)} />}
      <StyledLine color={colors.body} />
      <StyledContentContainer>
        <StyledFirstLine>
          <StyledName>{name}</StyledName>
        </StyledFirstLine>

        <StyledRampInfo>
          <StyledPointIcon src={pins[colors.name]} />
          {formatMessage({
            id: `pages.portfolio.project.location.accessibility.ramp`,
          })}
        </StyledRampInfo>
      </StyledContentContainer>
    </StyledContainer>
  )
}

HighwaysBlock.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.shape({
      original: PropTypes.string,
      large: PropTypes.string,
      medium: PropTypes.string,
      small: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  colors: PropTypes.shape({
    name: PropTypes.string,
    body: PropTypes.string,
    border: PropTypes.string,
  }).isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}
HighwaysBlock.defaultProps = {
  name: null,
  icon: null,
  isActive: false,
  onClick: () => null,
}
export default HighwaysBlock
