import { useIntl } from "gatsby-plugin-intl"

const getAccessibilityTooltipText = (type) => {
  const { formatMessage } = useIntl()

  const typeMap = {
    TRANSPORTATION_HUBS: "transportationHubsTooltip",
    KEY_LOCAL_ROADS: "roadsTooltip",
    HIGHWAYS: "highwaysTooltip",
  }
  return formatMessage({
    id: `pages.portfolio.project.location.accessibility.${typeMap[type]}`,
  })
}

export default getAccessibilityTooltipText
