import React from "react"
import PropTypes from "prop-types"
import getMediaUrl from "utils/getMediaUrl"
import { useIntl } from "gatsby-plugin-intl"
import useWindowSize from "utils/useWindowSize"
import theme from "styles/theme"

import {
  StyledHeadingWrapper,
  StyledHeadingContent,
  StyledBackIcon,
  StyledCategoryName,
  StyledImg,
  StyledLabel,
  StyledText,
  StyledDropdown,
  StyledBackButton,
  StyledLearnMore,
  StyledSelectionBox,
  StyledTextWrapper,
  StyledCategoryIcon,
} from "./CategoryDetails.styles"

const CategoryDetails = ({
  categoryData: { name, image, icon, whyDoesItMatter, description, type },
  categoryList,
  onCategoryChange,
  panePosition,
}) => {
  const { formatMessage } = useIntl()
  const size = useWindowSize()
  const isDesktop = size.width >= theme.breakpoints.medium

  return (
    <>
      {categoryList && !type && (
        <StyledDropdown
          options={categoryList}
          defaultValue={{ value: name, label: name }}
          onChange={(category) => onCategoryChange(category.value)}
          positionBottom={panePosition !== "bottom"}
          label={formatMessage({
            id: "pages.portfolio.project.location.highlights.categories",
          })}
        />
      )}
      <>
        <StyledHeadingWrapper>
          <StyledBackIcon onClick={() => onCategoryChange(null, type)} />
          <StyledHeadingContent>
            {type && <StyledSelectionBox type={type} />}
            {icon && isDesktop && (
              <StyledCategoryIcon src={getMediaUrl(icon)} alt={name} />
            )}
            <StyledCategoryName isVisible={Boolean(type)}>
              {name}
            </StyledCategoryName>
            {type === "MULTI_ADDRESS" && (
              <StyledLearnMore>
                {formatMessage({
                  id: "pages.portfolio.project.location.highlights.moreInfo",
                })}
              </StyledLearnMore>
            )}
          </StyledHeadingContent>
        </StyledHeadingWrapper>
        <StyledImg src={getMediaUrl(image)} alt={name} />
        {type && (
          <StyledBackButton onClick={() => onCategoryChange(null, type)}>
            <StyledBackIcon />
            {formatMessage({
              id: "pages.portfolio.project.location.highlights.categoryMap",
            })}
          </StyledBackButton>
        )}
        <StyledTextWrapper>
          {whyDoesItMatter && (
            <>
              <StyledLabel>
                {formatMessage({
                  id: `pages.portfolio.project.location.highlights.whyItMatters`,
                })}
              </StyledLabel>
              <StyledText>{whyDoesItMatter}</StyledText>
            </>
          )}
          <StyledLabel>
            {formatMessage({
              id: "pages.portfolio.project.location.highlights.description",
            })}
          </StyledLabel>
          <StyledText>{description}</StyledText>
        </StyledTextWrapper>
      </>
    </>
  )
}

CategoryDetails.propTypes = {
  categoryData: PropTypes.shape({
    description: PropTypes.string,
    icon: PropTypes.oneOfType([
      PropTypes.shape({
        original: PropTypes.string,
        large: PropTypes.string,
        medium: PropTypes.string,
        small: PropTypes.string,
      }),
      PropTypes.string,
    ]),
    image: PropTypes.oneOfType([
      PropTypes.shape({
        original: PropTypes.string,
        large: PropTypes.string,
        medium: PropTypes.string,
        small: PropTypes.string,
      }),
      PropTypes.string,
    ]),
    name: PropTypes.string,
    type: PropTypes.string,
    whyDoesItMatter: PropTypes.string,
    highlights: PropTypes.arrayOf(
      PropTypes.shape({
        coords: PropTypes.shape({
          type: PropTypes.string,
          coordinates: PropTypes.arrayOf(PropTypes.number),
        }),
        description: PropTypes.string,
        image: PropTypes.oneOfType([
          PropTypes.shape({
            original: PropTypes.string,
            large: PropTypes.string,
            medium: PropTypes.string,
            small: PropTypes.string,
          }),
          PropTypes.string,
        ]),
        name: PropTypes.string,
        type: PropTypes.string,
        whyDoesItMatter: PropTypes.string,
        subhighlights: PropTypes.arrayOf(
          PropTypes.shape({
            coords: PropTypes.shape({
              type: PropTypes.string,
              coordinates: PropTypes.arrayOf(PropTypes.number),
            }),
            description: PropTypes.string,
            id: PropTypes.string,
            image: PropTypes.oneOfType([
              PropTypes.shape({
                original: PropTypes.string,
                large: PropTypes.string,
                medium: PropTypes.string,
                small: PropTypes.string,
              }),
              PropTypes.string,
            ]),
            title: PropTypes.string,
          })
        ),
      })
    ),
  }).isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  categoryList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  panePosition: PropTypes.string.isRequired,
}

CategoryDetails.defaultProps = {
  categoryList: null,
}

export default CategoryDetails
