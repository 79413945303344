import React, { useState, useEffect } from "react"
import Autocomplete from "components/atoms/Autocomplete"
import browserWindow from "utils/browserWindow"
import { useIntl } from "gatsby-plugin-intl"

import PropTypes from "prop-types"
import getLocation from "utils/getLocation"
import formatTime from "utils/formatTime"

import Icon from "components/atoms/Icon"
import {
  StyledInputWrapper,
  StyledSwapButton,
  StyledForm,
  StyledDropdown,
} from "./ProjectLocationTrafficForm.styles"

const ProjectLocationTrafficForm = ({
  projectName,
  projectLocation,
  onChange,
  timezoneOffset,
}) => {
  const { formatMessage } = useIntl()

  const autocompleteSuggested = [
    { value: "Project", label: projectName },
    {
      value: "Anywhere",
      label: formatMessage({
        id: `pages.portfolio.project.location.traffic.anywhere`,
      }),
    },
  ]

  const defaultWhenOptions = [
    {
      label: formatMessage({
        id: `pages.portfolio.project.location.traffic.noTraffic`,
      }),
      value: "noTraffic",
    },
    {
      label: formatMessage({
        id: `pages.portfolio.project.location.traffic.rushHour`,
      }),
      value: "rushHour",
    },
  ]
  const [sugested, setSugested] = useState(autocompleteSuggested)
  const [options, setOptions] = useState([])
  const [whenOptions, setWhenOptions] = useState(defaultWhenOptions)
  const [disabledLabel, setDisabledLabel] = useState("From")
  const [fromValue, setFromValue] = useState(autocompleteSuggested[0])
  const [toValue, setToValue] = useState(autocompleteSuggested[1])
  const [whenValue, setWhenValue] = useState(defaultWhenOptions[0])

  const isCustomLocation = (value) =>
    value !== "Project" && value !== "Anywhere"

  useEffect(() => {
    onChange({ whenValue, fromValue, toValue })
  }, [whenValue, toValue, fromValue])

  useEffect(() => {
    const storedSugested = JSON.parse(
      browserWindow.sessionStorage.getItem("sugested")
    )

    if (storedSugested) {
      setSugested([...sugested, ...storedSugested])
    }
  }, [])

  const setDisabled = () =>
    setDisabledLabel(disabledLabel === "From" ? "To" : "From")

  const swapData = () => {
    const temp = fromValue
    setFromValue(toValue)
    setToValue(temp)
    setDisabled()
  }

  // eslint-disable-next-line no-unused-expressions
  const setValue = (value) =>
    disabledLabel === "From" ? setToValue(value) : setFromValue(value)

  const getUniqueSugestions = (selected) => {
    if (sugested.length >= 3) {
      const [first, second, ...rest] = sugested

      rest.unshift(selected)
      const uniqueRest = [...new Set(rest)]
      uniqueRest.length = uniqueRest.length > 3 ? 3 : uniqueRest.length

      browserWindow.sessionStorage.setItem(
        "sugested",
        JSON.stringify(uniqueRest)
      )
      return [first, second, ...uniqueRest]
    }
    return [...sugested, selected]
  }

  const handleSelectedPlace = (selected) => {
    const { value } = selected

    if (whenValue.value === "now" && !isCustomLocation(value)) {
      setWhenValue(defaultWhenOptions[0])
    }

    if (value === "Project") {
      swapData()
    }

    if (value === "Anywhere") {
      setValue(selected)
    }

    if (isCustomLocation(value)) {
      const uniqueSugestions = getUniqueSugestions(selected)
      setSugested(uniqueSugestions)
      setValue(selected)
      const currentProjectTime = Date.now() + timezoneOffset * 1000

      setWhenOptions([
        ...defaultWhenOptions,
        {
          label: `${formatMessage({
            id: `pages.portfolio.project.location.traffic.now`,
          })} (${formatTime(new Date(currentProjectTime))})`,
          value: "now",
        },
      ])
    } else {
      setWhenOptions(defaultWhenOptions)
    }
  }

  const handleSearch = (value) => {
    getLocation(value, projectLocation.coordinates, (results) => {
      const filteredResults = results.map((result) => ({
        label: result.description,
        value: result.description,
      }))
      setOptions(filteredResults)
    })
  }

  const handleTimeSelect = (selectedOption) => {
    setWhenValue(selectedOption)
  }

  return (
    <StyledForm>
      <StyledInputWrapper>
        <Autocomplete
          label="From"
          sugested={sugested}
          onSelected={handleSelectedPlace}
          onSearch={handleSearch}
          defaultValue={fromValue}
          options={options}
          disabled={disabledLabel === "From"}
        />
        <Autocomplete
          label="To"
          sugested={sugested}
          onSelected={handleSelectedPlace}
          onSearch={handleSearch}
          defaultValue={toValue}
          options={options}
          disabled={disabledLabel === "To"}
        />
        <StyledSwapButton onClick={swapData}>
          <Icon icon="replace" />
        </StyledSwapButton>
      </StyledInputWrapper>
      <StyledDropdown
        options={whenOptions}
        defaultValue={whenValue}
        onChange={handleTimeSelect}
        label={formatMessage({
          id: `pages.portfolio.project.location.traffic.when`,
        })}
      />
    </StyledForm>
  )
}

ProjectLocationTrafficForm.propTypes = {
  projectName: PropTypes.string.isRequired,
  projectLocation: PropTypes.shape({
    type: PropTypes.string,
    coordinates: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  timezoneOffset: PropTypes.number.isRequired,
}

export default ProjectLocationTrafficForm
