import { useIntl } from "gatsby-plugin-intl"

const getAccessibilityCategoryDescription = (type) => {
  const { formatMessage } = useIntl()

  const typeMap = {
    TRANSPORTATION_HUBS: "transportationHubs",
    KEY_LOCAL_ROADS: "roads",
    HIGHWAYS: "highways",
  }
  return formatMessage({
    id: `pages.portfolio.project.location.accessibility.${typeMap[type]}`,
  })
}

export default getAccessibilityCategoryDescription
