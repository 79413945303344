import styled, { css } from "styled-components"

const StyledContainer = styled.div`
  width: auto;
  height: 5.6rem;
  display: flex;
  background: ${({ theme }) => theme.color.white};
  padding: 0.8rem;
  position: relative;

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${({ theme }) => theme.color.secondary05};
    `}
`
const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.2rem;
`
const StyledFirstLine = styled.div`
  display: flex;
  align-items: center;
`

const StyledIcon = styled.img`
  min-height: 3.2rem;
  width: auto;
  margin-left: 2rem;
  height: 100%;
  align-self: center;
`
const StyledName = styled.span`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.67;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.color.secondary01};
  white-space: nowrap;
`

const StyledRampInfo = styled.span`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: 0.8rem;
  line-height: 1.67;
  letter-spacing: 0.17px;
  color: ${({ theme }) => theme.color.secondary02};
  align-items: center;
  display: flex;
  white-space: nowrap;
`
const StyledPointIcon = styled.img`
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.4rem;
`

const StyledLine = styled.span`
  display: block;
  position: absolute;
  left: 0.8rem;
  top: 0;
  width: 0.8rem;
  height: 5.6rem;

  ${({ color }) => color && `background: ${color}`}
`
export {
  StyledContainer,
  StyledContentContainer,
  StyledIcon,
  StyledName,
  StyledRampInfo,
  StyledFirstLine,
  StyledPointIcon,
  StyledLine,
}
