import styled, { css } from "styled-components"
import NavList from "components/molecules/NavList"

const actionColor = {
  oneHourDrive: css`
    background: ${({ theme }) => theme.color.septenary03};
  `,
  submarket: css`
    background: ${({ theme }) => theme.color.quaternary03};
  `,
  market: css`
    background: ${({ theme }) => theme.color.senary03};
  `,
}

const tabsClasses = ["oneHourDrive", "submarket", "market"]

const createStyles = () =>
  tabsClasses.map(
    (tabClass) => css`
      > a.${tabClass} {
        &.active,
        &:hover {
          &:after {
            ${actionColor[tabClass]}
          }
        }
      }
    `
  )

const StyledNavList = styled(NavList)`
  justify-content: initial;
  margin: 0 0.8rem 1.6rem;

  > a p {
    display: inline-block;
  }

  ${createStyles()}
`

const StyledProjectBlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden auto;
  padding: 0 0.8rem;

  > div {
    margin-bottom: 1.6rem;
  }

  ${({ theme }) => theme.mq.medium} {
    padding: 0 1rem 0 0.8rem;

    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.color.secondary06};
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: ${({ theme }) => theme.color.secondary06};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.color.secondary03};
    }
  }
`

export { StyledProjectBlocksWrapper, StyledNavList }
