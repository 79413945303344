import React from "react"
import CategoryInfoBox from "components/molecules/Map/CategoryInfoBox"
import getMediaUrl from "utils/getMediaUrl"

const prepareHiglihts = (category, onClick) => {
  return category.highlights.map((highlight) =>
    highlight.coords
      ? {
          markerIcon: getMediaUrl(category.icon.medium),
          subType: "customHighlight",
          isHighlighted: false,
          name: highlight.name,
          highlightType: highlight.type,
          onInfoBoxClick: () => onClick(highlight.name, highlight.type),
          infoBoxContent: (
            <CategoryInfoBox
              icon={getMediaUrl(category.icon.medium)}
              image={getMediaUrl(highlight.image)}
              categoryText={highlight.description}
              categoryName={highlight.name}
            />
          ),
          ...highlight.coords,
        }
      : prepareHiglihts(
          {
            ...category,
            highlights: [
              {
                ...highlight.subhighlights[0],
                type: highlight.type,
                name: highlight.name,
              },
            ],
          },
          onClick
        )
  )
}

const prepareHiglightsCoords = (
  highlightCategories,
  higlightedCategory,
  activeCategory,
  onClick,
  onCategoryHover
) => {
  if (highlightCategories && highlightCategories.length) {
    return activeCategory
      ? prepareHiglihts(
          highlightCategories.find(
            (category) => category.name === activeCategory
          ),
          onClick
        ).flat()
      : highlightCategories.map((category) => ({
          markerIcon: getMediaUrl(category.icon.medium),
          subType: "customHighlight",
          name: category.name,
          isHighlighted: higlightedCategory === category.name,
          onInfoBoxVisibilityChange: (value) =>
            onCategoryHover(value, category.name),
          onInfoBoxClick: () => onClick(category.name),
          infoBoxContent: (
            <CategoryInfoBox
              icon={getMediaUrl(category.icon.medium)}
              image={getMediaUrl(category.image)}
              categoryText={category.description}
              categoryName={category.name}
            />
          ),
          ...category.coords,
        }))
  }
  return []
}
export default prepareHiglightsCoords
