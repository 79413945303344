import React, { useState, useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import useWindowSize from "utils/useWindowSize"
import theme from "styles/theme"
import getLocationStatisticName from "utils/getLocationStatisticName"
import {
  StyledTooltipWrapper,
  StyledTooltipContent,
  StyledIcon,
} from "./ProjectLocationStatisticTooltip.styles"

const subTypePolygonColor = {
  ONE_HOUR_DRIVE: theme.color.septenary03,
  SUBMARKET: theme.color.quaternary03,
  MARKET: theme.color.senary03,
}

const ProjectLocationStatisticTooltip = ({ details }) => {
  const { formatMessage } = useIntl()
  const size = useWindowSize()
  const [labelDetails, setLabelDetails] = useState([])

  useEffect(() => {
    const getLabel = details.find(
      (detail) => detail.type === "PolygonInfoLabel"
    )
    setLabelDetails(getLabel)
  }, [details])

  return (
    <StyledTooltipWrapper>
      <StyledIcon icon="info" />
      {size.width >= theme.breakpoints.medium ? (
        <>
          {`${formatMessage({
            id: "pages.portfolio.project.location.statistics.surveyRegionName",
          })}:`}
          <div>
            <StyledTooltipContent
              color={subTypePolygonColor[labelDetails.subType]}
            >
              {labelDetails.subType &&
                getLocationStatisticName(labelDetails.subType)}
            </StyledTooltipContent>
          </div>
        </>
      ) : (
        <>
          {formatMessage({
            id:
              "pages.portfolio.project.location.statistics.statisticsInfoMobile",
          })}
        </>
      )}
    </StyledTooltipWrapper>
  )
}

ProjectLocationStatisticTooltip.propTypes = {
  details: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default ProjectLocationStatisticTooltip
