import React from "react"
import PropTypes from "prop-types"
import HubPurple from "components/molecules/Map/mapAssets/hub-purple.png"
import HubOrange from "components/molecules/Map/mapAssets/hub-orange.png"
import HubTeal from "components/molecules/Map/mapAssets/hub-teal.png"
import HubYellow from "components/molecules/Map/mapAssets/hub-yellow.png"

import {
  StyledContainer,
  StyledContentContainer,
  StyledName,
  StyledTraffic,
  StyledFirstLine,
  StyledIcon,
} from "./HubBlock.styles"

const pins = {
  orange: HubOrange,
  teal: HubTeal,
  yellow: HubYellow,
  purple: HubPurple,
}

const HubBlock = ({ name, trafficThroughput, colors, isActive, onClick }) => {
  return (
    <StyledContainer isActive={isActive} onClick={() => onClick(name)}>
      <StyledIcon src={pins[colors.name]} />

      <StyledContentContainer>
        <StyledFirstLine>
          <StyledName>{name}</StyledName>
        </StyledFirstLine>

        <StyledTraffic>{trafficThroughput}</StyledTraffic>
      </StyledContentContainer>
    </StyledContainer>
  )
}

HubBlock.propTypes = {
  name: PropTypes.string,
  trafficThroughput: PropTypes.string,
  colors: PropTypes.shape({
    name: PropTypes.string,
    body: PropTypes.string,
    border: PropTypes.string,
  }).isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}
HubBlock.defaultProps = {
  name: null,
  trafficThroughput: null,
  isActive: false,
  onClick: () => null,
}
export default HubBlock
