import React from "react"
import theme from "styles/theme"
import {
  StyledTime,
  StyledTimeWrapper,
  StyledIcon,
} from "./ProjectLocationTrafficTooltip.styles"

const timeColor = {
  10: theme.color.quaternary03,
  30: theme.color.senary03,
  60: theme.color.septenary03,
}

const ProjectLocationTrafficTooltip = () => {
  return (
    <StyledTimeWrapper>
      <StyledIcon icon="info" />
      Driving Time:
      <div>
        {["10", "30", "60"].map((timeValue) => (
          <StyledTime key={timeValue} timeColor={timeColor[timeValue]}>
            {timeValue}-min
          </StyledTime>
        ))}
      </div>
    </StyledTimeWrapper>
  )
}

export default ProjectLocationTrafficTooltip
