import styled from "styled-components"
import Dropdown from "components/atoms/Dropdown"

const StyledInputWrapper = styled.div`
  position: relative;
`

const StyledSwapButton = styled.span`
  width: 4rem;
  height: 4rem;
  border: solid 1px ${({ theme }) => theme.color.secondary04};
  background: ${({ theme }) => theme.color.white};
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 2.4rem;
    height: 2.4rem;
  }
`
const StyledForm = styled.form`
  padding: 0 0.8rem;
`

const StyledDropdown = styled(Dropdown)`
  width: 27.2rem;
`
export { StyledInputWrapper, StyledSwapButton, StyledForm, StyledDropdown }
