const prepareTrafficRoute = (response, formValue, showInfo = true) => {
  const { fromValue, toValue } = formValue || {}

  const routes = response.routes[0]
  const legs = routes.legs[0]
  const locationPosition =
    legs[fromValue.value === "Project" ? "end_location" : "start_location"]

  const distance = legs.distance.text
  const duration =
    (legs.duration_in_traffic && legs.duration_in_traffic.text) ||
    legs.duration.text
  const pathList = routes.overview_path
  const pointInTheMiddle = pathList[Math.round((pathList.length - 1) / 2)]
  const includeInfoWindow = showInfo
    ? [
        {
          type: "InfoWindow",
          coordinates: [pointInTheMiddle.lng(), pointInTheMiddle.lat()],
          duration,
          distance,
        },
        {
          type: "Point",
          coordinates: [locationPosition.lng(), locationPosition.lat()],
          subType: "destination",
        },
      ]
    : []

  return [
    {
      type: "Directions",
      coordinates: response,
      subType: "borderPolyline",
      name: toValue.label + fromValue.label,
    },
    {
      type: "Directions",
      coordinates: response,
      subType: "routePolyline",
      name: toValue.label + fromValue.label,
    },
    ...includeInfoWindow,
  ]
}

export default prepareTrafficRoute
