const findHiglight = (
  highlightCategories,
  activeCategory,
  selectedHighlight
) => {
  const selectedCategory = highlightCategories.find(
    (category) => category.name === activeCategory
  )

  const selectedSubHighlight = selectedCategory.highlights.find(
    (highlight) => highlight.name === selectedHighlight
  )

  return { ...selectedSubHighlight, icon: selectedCategory.icon }
}

export default findHiglight
