import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"

import getMediaUrl from "utils/getMediaUrl"

import {
  StyledTabTitle,
  StyledCategoriesWrapper,
  StyledCategory,
  StyledCategoryIcon,
  StyledCategoryName,
} from "./CategoryList.styles"

const CategoryList = ({
  highlightCategories,
  onHover,
  onClick,
  higlightedCategory,
}) => {
  const { formatMessage } = useIntl()

  return (
    <>
      <StyledTabTitle>
        {formatMessage({
          id: "pages.portfolio.project.location.highlights.categories",
        })}
        :
      </StyledTabTitle>
      <StyledCategoriesWrapper>
        {highlightCategories.map((category) => (
          <StyledCategory
            isActive={higlightedCategory === category.name}
            key={category.name}
            onMouseEnter={() => onHover(category.name)}
            onMouseLeave={() => onHover(null)}
            onClick={() => onClick(category.name)}
          >
            <StyledCategoryIcon
              src={getMediaUrl(category.icon)}
              alt={category.name}
            />
            <StyledCategoryName>{category.name}</StyledCategoryName>
          </StyledCategory>
        ))}
      </StyledCategoriesWrapper>
    </>
  )
}

CategoryList.propTypes = {
  highlightCategories: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      icon: PropTypes.oneOfType([
        PropTypes.shape({
          original: PropTypes.string,
          large: PropTypes.string,
          medium: PropTypes.string,
          small: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      image: PropTypes.oneOfType([
        PropTypes.shape({
          original: PropTypes.string,
          large: PropTypes.string,
          medium: PropTypes.string,
          small: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      name: PropTypes.string,
      whyDoesItMatter: PropTypes.string,
      highlights: PropTypes.arrayOf(
        PropTypes.shape({
          coords: PropTypes.shape({
            type: PropTypes.string,
            coordinates: PropTypes.arrayOf(PropTypes.number),
          }),
          description: PropTypes.string,
          image: PropTypes.oneOfType([
            PropTypes.shape({
              original: PropTypes.string,
              large: PropTypes.string,
              medium: PropTypes.string,
              small: PropTypes.string,
            }),
            PropTypes.string,
          ]),
          name: PropTypes.string,
          type: PropTypes.string,
          whyDoesItMatter: PropTypes.string,
          subhighlights: PropTypes.arrayOf(
            PropTypes.shape({
              coords: PropTypes.shape({
                type: PropTypes.string,
                coordinates: PropTypes.arrayOf(PropTypes.number),
              }),
              description: PropTypes.string,
              id: PropTypes.string,
              image: PropTypes.oneOfType([
                PropTypes.shape({
                  original: PropTypes.string,
                  large: PropTypes.string,
                  medium: PropTypes.string,
                  small: PropTypes.string,
                }),
                PropTypes.string,
              ]),
              title: PropTypes.string,
            })
          ),
        })
      ),
    })
  ).isRequired,
  onHover: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  higlightedCategory: PropTypes.string,
}

CategoryList.defaultProps = {
  higlightedCategory: null,
}
export default CategoryList
