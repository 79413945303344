import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"

import {
  StyledBoxWrapper,
  StyledImage,
  StyledTextWrapper,
  StyledHeadingWrapper,
  StyledIcon,
  StyledCategoryName,
  StyledText,
  StyledDetailsLink,
  StyledSelectionBox,
} from "./CategoryInfoBox.styles"

const CategoryInfoBox = ({
  icon,
  image,
  categoryName,
  categoryText,
  onClick,

  learnMore,
  ...restProps
}) => {
  const { formatMessage } = useIntl()

  return (
    <StyledBoxWrapper onClick={onClick} {...restProps}>
      <StyledImage src={image} alt={categoryName} />
      <StyledTextWrapper>
        <StyledHeadingWrapper>
          {icon ? (
            <StyledIcon src={icon} alt={categoryName} />
          ) : (
            <StyledSelectionBox />
          )}
          <StyledCategoryName>{categoryName}</StyledCategoryName>
        </StyledHeadingWrapper>
        <StyledText learnMore={learnMore}>{categoryText}</StyledText>
      </StyledTextWrapper>
      {learnMore && (
        <StyledDetailsLink>
          {formatMessage({
            id: "pages.portfolio.project.location.highlights.learnMore",
          })}
        </StyledDetailsLink>
      )}
    </StyledBoxWrapper>
  )
}

CategoryInfoBox.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.shape({
      original: PropTypes.string,
      large: PropTypes.string,
      medium: PropTypes.string,
      small: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  categoryName: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.shape({
      original: PropTypes.string,
      large: PropTypes.string,
      medium: PropTypes.string,
      small: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
  categoryText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  learnMore: PropTypes.bool,
}

CategoryInfoBox.defaultProps = {
  onClick: () => {},
  categoryName: "",
  icon: null,
  learnMore: true,
}

export default CategoryInfoBox
