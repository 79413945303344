import styled, { css } from "styled-components"
import Dropdown from "components/atoms/Dropdown"
import Checkbox from "components/atoms/Checkbox"

const accessibilityTypeValue = {
  KEY_LOCAL_ROADS: css`
    bottom: 8.5rem;
  `,
  HIGHWAYS: css`
    bottom: 4.5rem;
  `,
  TRANSPORTATION_HUBS: css`
    bottom: 0.5rem;
  `,
}

const beforeStyles = css`
  content: "";
  display: block;
  width: 0.4rem;
  height: 100%;
  position: absolute;
  left: -0.8rem;
`

const StyledTabTitle = styled.span`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.75;
  color: ${({ theme }) => theme.color.secondary01};
  margin-bottom: 0.4rem;
  padding-bottom: 4px;
  border-bottom: solid 1px ${({ theme }) => theme.color.secondary04};
`

const StyledAccessibilityWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledAccessibilityIcon = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1.2rem;

  svg {
    fill: ${({ theme }) => theme.color.secondary02};
    transition: fill 0.5s;
  }
`

const StyledAccessibility = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem;
  margin-left: -0.8rem;
  cursor: pointer;
  position: relative;
  color: ${({ theme }) => theme.color.secondary02};
  transition: color 0.5s;

  &:last-of-type {
    border-bottom: none;
  }

  &:last-child {
    margin-bottom: -0.8rem;
  }

  &:hover {
    &:before {
      ${beforeStyles}
      background: ${({ theme }) => theme.color.main03};
    }

    color: ${({ theme }) => theme.color.secondary01};
    svg {
      fill: ${({ theme }) => theme.color.secondary01};
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      &:before {
        ${beforeStyles}
        background: ${({ theme }) => theme.color.main03};
      }
      color: ${({ theme }) => theme.color.secondary01};
      svg {
        fill: ${({ theme }) => theme.color.secondary01};
      }
    `}
`

const StyledAccessibilityName = styled.span`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.67;
  letter-spacing: 0.25px;
  color: inherit;
`

const StyledMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 31.1rem;
  padding: 0 0.8rem;
`

const StyledDropdown = styled(Dropdown)`
  display: block;
  margin-bottom: 1.1rem;
  padding-left: 7.4rem;
  width: 100%;

  > div {
    width: calc(100% - 7.4rem);
    ${({ positionBottom }) =>
      positionBottom &&
      css`
        bottom: initial;
      `}
  }

  ${({ theme }) => theme.mq.medium} {
    display: none;
  }
`

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.color.secondary04};
  flex: 1;
  overflow-y: auto;
  padding-top: 0.3rem;

  > div {
    margin-top: 0.8rem;
  }
`

const StyledCheckbox = styled(Checkbox)`
  padding: 1.6rem 1.6rem 1.6rem 3.5rem;

  > span {
    top: 50%;
    transform: translateY(-50%);
  }
`

const StyledCheckboxWrapper = styled.div`
  position: absolute;
  bottom: 8.5rem;
  right: 0;

  ${({ type }) => type && accessibilityTypeValue[type]}
`

export {
  StyledTabTitle,
  StyledAccessibilityWrapper,
  StyledAccessibility,
  StyledAccessibilityIcon,
  StyledAccessibilityName,
  StyledMobileContainer,
  StyledDropdown,
  StyledList,
  StyledCheckbox,
  StyledCheckboxWrapper,
}
