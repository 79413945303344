import styled from "styled-components"

const StyledProjectBlock = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-template-rows: auto;

  > svg {
    grid-row: 1/3;
    width: 6.4rem;
    height: 6.4rem;
    margin-right: 1.6rem;
  }
`

const StyledBlockLabel = styled.span`
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.67;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.color.secondary02};
  margin-top: 0.8rem;
`

const StyledBlockText = styled.span`
  font-size: ${({ theme }) => theme.font.size.s};
  line-height: 1.71;
  color: ${({ theme }) => theme.color.secondary01};
  margin-bottom: 0.8rem;
  word-break: break-word;
`

export { StyledProjectBlock, StyledBlockLabel, StyledBlockText }
