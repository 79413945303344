import styled, { css } from "styled-components"
import ChevronLeft from "assets/icons/chevron-left.svg"
import { transparentize } from "polished"
import Image from "components/atoms/Image"
import Dropdown from "components/atoms/Dropdown"

const StyledHeadingWrapper = styled.div`
  order: 2;

  ${({ theme }) => theme.mq.medium} {
    order: 1;
    display: flex;
    align-items: center;
    margin-bottom: 1.2rem;
    min-height: fit-content;
  }
`
const StyledHeadingContent = styled.div`
  display: flex;
  width: calc(100% - 1.8rem);
  flex-direction: column;

  span {
    margin-right: 0.8rem;
  }

  ${({ theme }) => theme.mq.medium} {
    flex-direction: row;
    padding-left: 3.2rem;

    span {
      flex-shrink: 0;
      margin-top: 0.3rem;
      margin-right: 1.6rem;
    }
  }
`
const StyledBackIcon = styled(ChevronLeft)`
  display: none;
  width: auto;
  height: 1.9rem;
  cursor: pointer;

  ${({ theme }) => theme.mq.medium} {
    display: block;
  }
`
const StyledCategoryName = styled.h5`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.md};
  line-height: 1.75;
  letter-spacing: normal;
  color: ${({ theme }) => theme.color.secondary01};
  margin: 1.6rem 0 0;
  display: flex;
  font-weight: normal;

  ${({ isVisible }) =>
    !isVisible &&
    css`
      display: none;
    `}

  ${({ theme }) => theme.mq.medium} {
    margin: 0;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    display: block;
  }
`

const StyledImg = styled(Image)`
  width: 100%;
  min-height: 18.4rem;
  height: 18.4rem;
  object-fit: cover;
  margin-bottom: 0.8rem;
  order: 1;

  ${({ theme }) => theme.mq.medium} {
    order: 2;
    display: flex;
    align-items: center;
    margin-bottom: 1.2rem;
  }
`
const StyledLabel = styled.span`
  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.71;
  color: ${({ theme }) => theme.color.secondary02};
  margin-top: 1.6rem;
  order: 3;
  display: block;

  &:first-of-type {
    margin-top: 0;
  }
`
const StyledText = styled.p`
  font-size: ${({ theme }) => theme.font.size.s};
  line-height: 1.71;
  color: ${({ theme }) => theme.color.secondary01};
  order: 3;
  hyphens: auto;
  text-align: justify;
`
const StyledDropdown = styled(Dropdown)`
  display: block;
  margin-bottom: 1.6rem;
  margin-left: 0.8rem;
  padding-left: 8.2rem;
  width: 100%;

  > div {
    width: calc(100% - 8.2rem);
    ${({ positionBottom }) =>
      positionBottom &&
      css`
        bottom: initial;
      `}
  }

  ${({ theme }) => theme.mq.medium} {
    display: none;
  }
`

const StyledBackButton = styled.button`
  background: ${({ theme }) => theme.color.white};
  width: 12.6rem;
  height: 3.2rem;
  box-shadow: 0 2px 4px 0
    ${({ theme }) => transparentize(0.88, theme.color.black)};
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.xs};
  line-height: 1.67;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.color.secondary01};
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 2;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  padding: 0 0.8rem;

  > ${StyledBackIcon} {
    display: initial;
    height: 1.4rem;
    margin-right: 0.8rem;
  }

  ${({ theme }) => theme.mq.medium} {
    display: none;
  }
`

const StyledLearnMore = styled.p`
  font-size: ${({ theme }) => theme.font.size.xs};
  font-family: ${({ theme }) => theme.font.family.body};
  line-height: 1.67;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.color.main03};
  order: 3;
  margin: 0.8rem 0;

  ${({ theme }) => theme.mq.medium} {
    display: none;
  }
`

const typeStyles = {
  MULTI_ADDRESS: css`
    border-style: solid;
    border-width: 2px;
    border-color: ${({ theme }) => theme.color.main03};
    background-color: ${({ theme }) =>
      transparentize(0.85, theme.color.main03)};
  `,
  SINGLE_ADDRESS: css`
    background-color: ${({ theme }) => theme.color.quinary03};

    border-radius: 50%;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 0.6rem;
      height: 0.6rem;
      background: ${({ theme }) => theme.color.white};
      transform: translateY(-50%) translateX(-50%) rotate(45deg);
    }
  `,
}

const StyledSelectionBox = styled.span`
  display: none;

  ${({ theme }) => theme.mq.medium} {
    display: block;
    width: 2.4rem;
    height: 2.4rem;

    ${({ type }) => type && typeStyles[type]}
  }
`
const StyledTextWrapper = styled.div`
  order: 2;
  overflow-y: auto;
  flex: 1;
  line-height: 1.71;
  color: ${({ theme }) => theme.color.secondary01};
  padding: 0 0 1.6rem;

  ${({ theme }) => theme.mq.medium} {
    margin-right: -0.6rem;
    margin-top: 0.8rem;
    padding: 0 1rem 0 0;

    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.color.secondary06};
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: ${({ theme }) => theme.color.secondary06};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.color.secondary03};
    }
  }
`
const StyledCategoryIcon = styled(Image)`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1.2rem;
  flex-shrink: 0;

  img {
    transition: filter 0.5s;

    /* make icon black to apply other filters more accordingly */
    filter: brightness(0) saturate(100%);
    object-fit: contain;
  }
`

export {
  StyledHeadingWrapper,
  StyledHeadingContent,
  StyledBackIcon,
  StyledCategoryName,
  StyledImg,
  StyledLabel,
  StyledText,
  StyledDropdown,
  StyledBackButton,
  StyledLearnMore,
  StyledSelectionBox,
  StyledTextWrapper,
  StyledCategoryIcon,
}
