const pickOnlyPoint = (geometryList, text) =>
  geometryList
    .filter((geometry) => geometry.type === "Point")
    .map((geometry) => ({ ...geometry, text, subType: text }))

const getTrafficLabel = (traffic, polygonValue) => {
  return [
    ...pickOnlyPoint(traffic[`${polygonValue}10Min`].geometries, `10`),
    ...pickOnlyPoint(traffic[`${polygonValue}30Min`].geometries, `30`),
    ...pickOnlyPoint(traffic[`${polygonValue}60Min`].geometries, `60`),
  ].map((label) => ({ ...label, type: "PolygonInfoLabel" }))
}

export default getTrafficLabel
