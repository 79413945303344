const checkDigit = (t) => {
  return t < 10 ? `0${t}` : t
}

const formatTime = (d = new Date()) => {
  let hour = d.getUTCHours()

  const a = hour >= 12 ? "pm" : "am"
  hour %= 12
  hour = hour || 12

  hour = checkDigit(hour)
  const minute = checkDigit(d.getMinutes())

  return `${hour}:${minute} ${a}`
}

export default formatTime
