const preparePointData = (point) =>
  Array.isArray(point)
    ? {
        lat: point[1],
        lng: point[0],
      }
    : point

const getRoute = (origin, destination, departureTime, callback) => {
  const directionsService = new window.google.maps.DirectionsService()

  directionsService.route(
    {
      origin: preparePointData(origin),
      destination: preparePointData(destination),
      travelMode: window.google.maps.TravelMode.DRIVING,
      drivingOptions: {
        departureTime,
      },
    },
    (response, status) => {
      if (status === "OK") {
        callback(response)
      }
    }
  )
}

export default getRoute
