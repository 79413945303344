const getLocation = (input, point, callback) => {
  const controlButtonDiv = document.createElement("div")
  const service = new window.google.maps.places.AutocompleteService(
    controlButtonDiv
  )
  const location = {
    lat: () => point[1],
    lng: () => point[0],
  }

  service.getPlacePredictions(
    {
      input,
      location,
      radius: 800000, // Search radius in meters
    },
    (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        callback(results)
      }
    }
  )
}

export default getLocation
