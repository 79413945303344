import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"
import theme from "styles/theme"
import useWindowSize from "utils/useWindowSize"
import getAccessibilityCategoryDescription from "utils/getAccessibilityCategoryDescription"
import HighwaysIcon from "assets/icons/highways.svg"
import LocalRoadsIcon from "assets/icons/local-roads.svg"
import TransportationHubsIcon from "assets/icons/transportation-hubs.svg"
import activePolylineSubject from "utils/activePolylineSubject"
import HighwaysBlock from "./HighwaysBlock"
import HubBlock from "./HubBlock"
import KeyRoadsBlock from "./KeyRoadsBlock"

import {
  StyledTabTitle,
  StyledAccessibilityWrapper,
  StyledAccessibility,
  StyledAccessibilityIcon,
  StyledAccessibilityName,
  StyledDropdown,
  StyledList,
  StyledMobileContainer,
  StyledCheckbox,
  StyledCheckboxWrapper,
} from "./ProjectLocationAccessibility.styles"

const { color } = theme

const colors = {
  0: {
    name: "teal",
    body: color.quaternary03,
    border: color.quaternary02,
  },
  1: { name: "orange", body: color.septenary03, border: color.septenary02 },
  2: { name: "yellow", body: color.senary03, border: color.senary02 },
  3: { name: "purple", body: color.octonary01, border: color.octonary02 },
}

const typeOrder = ["KEY_LOCAL_ROADS", "HIGHWAYS", "TRANSPORTATION_HUBS"]

const typeIcons = (type) => {
  const getIcon = {
    KEY_LOCAL_ROADS: <LocalRoadsIcon />,
    HIGHWAYS: <HighwaysIcon />,
    TRANSPORTATION_HUBS: <TransportationHubsIcon />,
  }
  return getIcon[type]
}

const ProjectLocationAccessibility = ({
  accessibilityData,
  onAccessibilityChange,
  selectedAccessibility,
  selectedAccessibilityValue,
  onAccessibilityValueChange,
}) => {
  const { formatMessage } = useIntl()
  const size = useWindowSize()
  const isDesktop = size.width >= theme.breakpoints.medium

  const activeAccessibilityData = accessibilityData.find(
    (currentAccessibility) =>
      currentAccessibility.accessibilityType === selectedAccessibility
  )

  const handleShowingLabels = (value) => {
    activePolylineSubject.notify({
      action: "switch",
      value: value ? "all" : null,
    })
  }

  const createListItem = (value, index) => {
    const { name, icon, aproxTime, trafficThroughput } = value
    const accesibilityLabels = {
      TRANSPORTATION_HUBS: (
        <HubBlock
          key={name}
          name={name}
          colors={colors[index % 4]}
          trafficThroughput={trafficThroughput}
          onClick={onAccessibilityValueChange}
          isActive={name === selectedAccessibilityValue}
        />
      ),
      KEY_LOCAL_ROADS: (
        <KeyRoadsBlock
          key={name}
          name={name}
          colors={colors[index % 4]}
          trafficThroughput={trafficThroughput}
          aproxTime={aproxTime}
          onClick={onAccessibilityValueChange}
          isActive={name === selectedAccessibilityValue}
        />
      ),
      HIGHWAYS: (
        <HighwaysBlock
          key={name}
          name={name}
          colors={colors[index % 4]}
          icon={icon}
          onClick={onAccessibilityValueChange}
          isActive={name === selectedAccessibilityValue}
        />
      ),
    }

    return accesibilityLabels[selectedAccessibility]
  }

  return (
    <>
      {isDesktop ? (
        <>
          <StyledTabTitle>
            {formatMessage({
              id: "pages.portfolio.project.location.accessibility.show",
            })}
            :
          </StyledTabTitle>
          <StyledAccessibilityWrapper>
            {typeOrder
              .map((type) =>
                accessibilityData.find(
                  (accessibility) => accessibility.accessibilityType === type
                )
              )
              .map((accessibility) => (
                <StyledAccessibility
                  isSelected={
                    selectedAccessibility === accessibility.accessibilityType
                  }
                  key={accessibility.accessibilityType}
                  onClick={() =>
                    onAccessibilityChange(accessibility.accessibilityType)
                  }
                >
                  <StyledAccessibilityIcon>
                    {typeIcons(accessibility.accessibilityType)}
                  </StyledAccessibilityIcon>
                  <StyledAccessibilityName>
                    {getAccessibilityCategoryDescription(
                      accessibility.accessibilityType
                    )}
                  </StyledAccessibilityName>
                </StyledAccessibility>
              ))}
          </StyledAccessibilityWrapper>
          <StyledCheckboxWrapper type={selectedAccessibility}>
            <StyledCheckbox
              id="accessibilityCheckbox"
              labelText={formatMessage({
                id: "pages.portfolio.project.location.accessibility.showAll",
              })}
              onChange={handleShowingLabels}
            />
          </StyledCheckboxWrapper>
        </>
      ) : (
        <StyledMobileContainer>
          {selectedAccessibility && (
            <>
              <StyledDropdown
                options={accessibilityData.map((accessibility) => ({
                  value: accessibility.accessibilityType,
                  label: getAccessibilityCategoryDescription(
                    accessibility.accessibilityType
                  ),
                }))}
                defaultValue={{
                  value: activeAccessibilityData.accessibilityType,
                  label: getAccessibilityCategoryDescription(
                    activeAccessibilityData.accessibilityType
                  ),
                }}
                onChange={(category) => onAccessibilityChange(category.value)}
                label={formatMessage({
                  id: "pages.portfolio.project.location.accessibility.show",
                })}
              />
              <StyledList>
                {activeAccessibilityData.values.map((value, index) =>
                  createListItem(value, index)
                )}
              </StyledList>
            </>
          )}
        </StyledMobileContainer>
      )}
    </>
  )
}

ProjectLocationAccessibility.propTypes = {
  accessibilityData: PropTypes.arrayOf(
    PropTypes.shape({
      accessibilityType: PropTypes.string,
      icon: PropTypes.oneOfType([
        PropTypes.shape({
          original: PropTypes.string,
          large: PropTypes.string,
          medium: PropTypes.string,
          small: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      values: PropTypes.arrayOf(
        PropTypes.shape({
          aproxTime: PropTypes.string,
          coords: PropTypes.shape({
            type: PropTypes.string,
            coordinates: PropTypes.arrayOf(
              PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
            ),
          }),
          name: PropTypes.string,
          trafficThroughput: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  onAccessibilityChange: PropTypes.func.isRequired,
  selectedAccessibility: PropTypes.string,
  selectedAccessibilityValue: PropTypes.string,
  onAccessibilityValueChange: PropTypes.func.isRequired,
}
ProjectLocationAccessibility.defaultProps = {
  selectedAccessibility: null,
  selectedAccessibilityValue: null,
}
export default ProjectLocationAccessibility
