import PropTypes from "prop-types"

const ppLocationProptypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  slug: PropTypes.string,
  location: PropTypes.shape({
    leftTabColumnAddressLine1: PropTypes.string,
    leftTabColumnAddressLine2: PropTypes.string,
    leftTabColumnAddressLine3: PropTypes.string,
    statistics: PropTypes.arrayOf(
      PropTypes.shape({
        coords: PropTypes.shape({
          type: PropTypes.string,
          coordinates: PropTypes.arrayOf(
            PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
          ),
        }),
        blueCollarJobs: PropTypes.number,
        medianAge: PropTypes.number,
        medianHouseholdIncome: PropTypes.number,
        medianNetWorth: PropTypes.number,
        population: PropTypes.number,
        populationDensity: PropTypes.number,
        section: PropTypes.string,
        serviceJobs: PropTypes.number,
        surveyRegionName: PropTypes.string,
        totalNumberOfBusiness: PropTypes.number,
        totalNumberOfEmployees: PropTypes.number,
        whiteCollarJobs: PropTypes.number,
      })
    ),
    traffic: PropTypes.shape({
      noTrafficAnywhereProject10Min: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.arrayOf(
          PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
        ),
      }),
      noTrafficAnywhereProject30Min: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.arrayOf(
          PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
        ),
      }),
      noTrafficAnywhereProject60Min: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.arrayOf(
          PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
        ),
      }),
      noTrafficProjectAnywhere10Min: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.arrayOf(
          PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
        ),
      }),
      noTrafficProjectAnywhere30Min: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.arrayOf(
          PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
        ),
      }),
      noTrafficProjectAnywhere60Min: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.arrayOf(
          PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
        ),
      }),
      rushHourAnywhereProject10Min: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.arrayOf(
          PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
        ),
      }),
      rushHourAnywhereProject30Min: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.arrayOf(
          PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
        ),
      }),
      rushHourAnywhereProject60Min: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.arrayOf(
          PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
        ),
      }),
      rushHourProjectAnywhere10Min: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.arrayOf(
          PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
        ),
      }),
      rushHourProjectAnywhere30Min: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.arrayOf(
          PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
        ),
      }),
      rushHourProjectAnywhere60Min: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.arrayOf(
          PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
        ),
      }),
    }),
    highlightCategories: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        icon: PropTypes.oneOfType([
          PropTypes.shape({
            original: PropTypes.string,
            large: PropTypes.string,
            medium: PropTypes.string,
            small: PropTypes.string,
          }),
          PropTypes.string,
        ]),
        image: PropTypes.oneOfType([
          PropTypes.shape({
            original: PropTypes.string,
            large: PropTypes.string,
            medium: PropTypes.string,
            small: PropTypes.string,
          }),
          PropTypes.string,
        ]),
        name: PropTypes.string,
        coords: PropTypes.shape({
          type: PropTypes.string,
          coordinates: PropTypes.arrayOf(PropTypes.number),
        }),
        whyDoesItMatter: PropTypes.string,
        highlights: PropTypes.arrayOf(
          PropTypes.shape({
            coords: PropTypes.shape({
              type: PropTypes.string,
              coordinates: PropTypes.arrayOf(PropTypes.number),
            }),
            description: PropTypes.string,
            image: PropTypes.oneOfType([
              PropTypes.shape({
                original: PropTypes.string,
                large: PropTypes.string,
                medium: PropTypes.string,
                small: PropTypes.string,
              }),
              PropTypes.string,
            ]),
            name: PropTypes.string,
            type: PropTypes.string,
            whyDoesItMatter: PropTypes.string,
            subhighlights: PropTypes.arrayOf(
              PropTypes.shape({
                coords: PropTypes.shape({
                  type: PropTypes.string,
                  coordinates: PropTypes.arrayOf(PropTypes.number),
                }),
                description: PropTypes.string,
                id: PropTypes.string,
                image: PropTypes.oneOfType([
                  PropTypes.shape({
                    original: PropTypes.string,
                    large: PropTypes.string,
                    medium: PropTypes.string,
                    small: PropTypes.string,
                  }),
                  PropTypes.string,
                ]),
                title: PropTypes.string,
                edge1: PropTypes.shape({
                  id: PropTypes.string,
                }),
              })
            ),
          })
        ),
      })
    ),
    accessibility: PropTypes.arrayOf(
      PropTypes.shape({
        accessibilityType: PropTypes.string,
        icon: PropTypes.oneOfType([
          PropTypes.shape({
            original: PropTypes.string,
            large: PropTypes.string,
            medium: PropTypes.string,
            small: PropTypes.string,
          }),
          PropTypes.string,
        ]),
        displayFrame: PropTypes.any,
        values: PropTypes.arrayOf(
          PropTypes.shape({
            aproxTime: PropTypes.string,
            icon: PropTypes.oneOfType([
              PropTypes.shape({
                original: PropTypes.string,
                large: PropTypes.string,
                medium: PropTypes.string,
                small: PropTypes.string,
              }),
              PropTypes.string,
            ]),
            coords: PropTypes.shape({
              type: PropTypes.string,
              coordinates: PropTypes.arrayOf(
                PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
              ),
            }),
            name: PropTypes.string,
            trafficThroughput: PropTypes.string,
          })
        ),
      })
    ),
  }),
  projectPosition: PropTypes.shape({
    area: PropTypes.shape({
      type: PropTypes.string,
      coordinates: PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
      ),
    }),
    point: PropTypes.shape({
      type: PropTypes.string,
      coordinates: PropTypes.arrayOf(PropTypes.number),
    }),
  }),
  projectTag: PropTypes.string,
  overview: PropTypes.shape({
    city: PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.oneOfType([
        PropTypes.shape({
          original: PropTypes.string,
          large: PropTypes.string,
          medium: PropTypes.string,
          small: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      description: PropTypes.string,
    }),
  }),
})

export default ppLocationProptypes
